<template>

  <div class="modal fade p-0" id="courseModel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="d-flex flex-wrap w-100 position-relative event-auto">
            <div class="card shadow-none">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left w-100">
                  <h4 class="card-title text-white">Course Information</h4>
                  <p class="card-category mb-0">{{courseId?'Edit Courses Modal':'Add Courses Modal'}}</p>
                </div>
              </div>
              <div class="card-body">
                <form
                  class="custom-form mt-2"
                  @submit="onSubmit"
                >
                  <div class="row">
                    <!-- General Information  -->
                    <div class="col-12">
                      <h3 class="font-18 fw-semibold mb-0">
                        General Information 
                      </h3>
                    </div>
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <Field
                          type="text"
                          name="courseTitle"
                          v-model="values.courseTitle"
                          :class="{
                          'border-0': true,
                          'position-relative': true,
                          'w-100': true,
                          'active': values.courseTitle
                        }"
                        />
                        <label for="" class="mb-0 placeholder-label"
                          >Courses Title</label
                        >
                        <p v-if="errors.courseTitle" class="invalid-message mb-0 pt-2">
                          {{errors.courseTitle}}
                        </p>
                      </div>
                    </div>
  
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <Field
                          type="text"
                          id="courseDescriptionQuery"
                          name="courseDescriptionQuery"
                          :class="[
                            values.courseDescriptionQuery? 'active' : '',
                            'border-0 input-btn-right position-relative w-100 pe-5 mt-0',
                          ]"
                          v-model="values.courseDescriptionQuery"
                        />
                        <label for="" class="mb-0 placeholder-label">Description Prompt</label>
                        <p v-if="errors.courseDescriptionQuery" class="invalid-message mb-0 pt-2">
                        {{ errors.courseDescriptionQuery }}
                        </p>
                        <span
                        @click="chatGptPrompt(values.courseDescriptionQuery,'c-desc','courseDescSearch','courseDescLoader')"
                          role="button"
                          class="size-30 position-absolute top-30 translate-middle-y end-0 z-5 d-inline-flex bg-black shadow rounded-circle"
                        >
                          <i
                            id="courseDescSearch"
                            class="bi bi-search fs-6 text-white m-auto"
                          ></i>
                          <div
                            id="courseDescLoader"
                            class="d-none spinner-border spinner-border-sm text-white m-auto"
                          ></div>
                        </span>
                      </div>
                    </div>
  
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <label
                          for=""
                          class="placeholder-label position-relative mb-3"
                          >Course Description</label>
                        <Field
                          name="editorCourseDescription"
                          v-model="values.editorCourseDescription"
                          v-slot="{ field }"
                        >
                        <ckediter 
                        :editer-classes="'custom-ckeditor'"
                        :editer-name="'editorCourseDescription'"
                        :editer-id="'editorCourseDescription'"
                        :editer-value="values.editorCourseDescription"
                        :editer-v-bind="field"
                        @update:editer-value="setFieldValue('editorCourseDescription',$event)"
                        :editer-reset="restEditer"
                        :setEditerData="setEditerContent[1]"
                        />
                        </Field>
                      </div>
                      <!-- <p v-if="errors.courseDescription" class="invalid-message mb-0 pt-2">
                          {{errors.courseDescription}}
                      </p> -->
                      
                    </div>
                    <div class="col-12 d-flex mt-4">
                      <button type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#previewModel"
                      @click.prevent="setPreviewContent('c-desc')"
                      class="btn btn-secondary ms-auto">
                        Preview
                      </button>
                    </div>
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <Field
                          type="text"
                          id="shortDescriptionQuery"
                          name="shortDescriptionQuery"
                          :class="[
                            (!values.shortDescriptionQuery) ? '' : 'active',
                            'border-0 input-btn-right position-relative w-100 pe-5 mt-0',
                          ]"
                          v-model="values.shortDescriptionQuery"
                        />
                        <label for="" class="mb-0 placeholder-label">Short Description Prompt</label>
                        <!-- <p v-if="errors.shortDescriptionQuery" class="invalid-message mb-0 pt-2">
                        {{ errors.shortDescriptionQuery }}
                        </p> -->
                        <span
                        @click="chatGptPrompt(values.shortDescriptionQuery,'shortDesc','shortdescSearch','shortSearchLoader')"
                          role="button"
                          class="size-30 position-absolute top-30 translate-middle-y end-0 z-5 d-inline-flex bg-black shadow rounded-circle"
                        >
                          <i
                            id="shortdescSearch"
                            class="bi bi-search fs-6 text-white m-auto"
                          ></i>
                          <div
                            id="shortSearchLoader"
                            class="d-none spinner-border spinner-border-sm text-white m-auto"
                          ></div>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <label
                          for=""
                          class="placeholder-label position-relative mb-3"
                          >Short Description</label>
                        <Field
                          name="editorShortDescription"
                          v-model="values.editorShortDescription"
                          v-slot="{ field }"
                        >
                        <ckediter 
                        :editer-classes="'custom-ckeditor'"
                        :editer-name="'editorShortDescription'"
                        :editer-id="'editorShortDescription'"
                        :editer-value="values.editorShortDescription"
                        :editer-v-bind="field"
                        @update:editer-value="setFieldValue('editorShortDescription',$event)"
                        :editer-reset="restEditer"
                        :setEditerData="setEditerContent[0]"
                        ref="imckedi"
                        />
                        </Field>
                    </div>
                      <!-- <p v-if="errors.shortDescription" class="invalid-message mb-0 pt-2">
                          {{errors.shortDescription}}
                      </p> -->
                    </div>
                    <div class="col-12 d-flex mt-4">
                      <button type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#previewModel"
                      @click.prevent="setPreviewContent('c-shortDesc')"
                      class="btn btn-secondary ms-auto">
                        Preview
                      </button>
                    </div>
                    <div class="col-12 mt-4">
                      <h3 class="font-18 fw-semibold mb-0">Information Page</h3>
                    </div>
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <Field
                          type="text"
                          name="infoPageTitle"
                          v-model="values.infoPageTitle"
                          :class="[
                            (!values.infoPageTitle)? '' : 'active',
                            'border-0 position-relative w-100',
                          ]"
                        />
                        <label for="" class="mb-0 placeholder-label"
                          >Title</label
                        >
                        <p v-if="errors.infoPageTitle" class="invalid-message mb-0 pt-2">
                          {{errors.infoPageTitle}}
                        </p>
                      </div>
                    </div>
  
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <Field
                          type="text"
                          id="courseTextQuery"
                          name="courseTextQuery"
                          :class="[
                            (!values.courseTextQuery) ? '' : 'active',
                            'border-0 input-btn-right position-relative w-100 pe-5 mt-0',
                          ]"
                          v-model="values.courseTextQuery"
                        />
                        <label for="" class="mb-0 placeholder-label">Text Prompt</label>
                        <!-- <p v-if="errors.courseTextQuery" class="invalid-message mb-0 pt-2">
                        {{ errors.courseTextQuery }}
                        </p> -->
                        <span
                        @click="chatGptPrompt(values.courseTextQuery,'c-text','courseTextSearch','courseTextLoader')"
                          role="button"
                          class="size-30 position-absolute top-30 translate-middle-y end-0 z-5 d-inline-flex bg-black shadow rounded-circle"
                        >
                          <i
                            id="courseTextSearch"
                            class="bi bi-search fs-6 text-white m-auto"
                          ></i>
                          <div
                            id="courseTextLoader"
                            class="d-none spinner-border spinner-border-sm text-white m-auto"
                          ></div>
                        </span>
                      </div>
                    </div>
  
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <label
                          for=""
                          class="placeholder-label position-relative mb-3"
                          >Text</label>
                        <Field
                          name="editorText"
                          v-model="values.editorText"
                          v-slot="{ field }"
                        >
                        <ckediter 
                        :editer-classes="'custom-ckeditor'"
                        :editer-name="'editorText'"
                        :editer-id="'editorText'"
                        :editer-value="values.editorText"
                        :editer-v-bind="field"
                        @update:editer-value="setFieldValue('editorText',$event)"
                        :editer-reset="restEditer"
                        :setEditerData="setEditerContent[2]"
                        />
                        </Field>
                      </div>
                        <!-- <p v-if="errors.courseText" class="invalid-message mb-0 pt-2">
                            {{errors.courseText}}
                        </p> -->
                    </div>
                    <div class="col-12 d-flex mt-4">
                      <button type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#previewModel"
                      @click.prevent="setPreviewContent('text')"
                      class="btn btn-secondary ms-auto"
                      >
                        Preview
                      </button>
                    </div>
  
                    <!-- Additional Information  -->
  
                    <div class="col-12 mt-4">
                      <h3 class="font-18 fw-semibold mb-0">
                        Additional Information
                      </h3>
                    </div>
                    <div class="col-lg-6 mt-4">
                      <div class="position-relative">
                        <Field
                          type="number"
                          name="price"
                          v-model="values.price"
                          :class="[
                            values.price? 'active' : '',
                            'border-0 position-relative w-100',
                          ]"
                        />
                        <label for="" class="mb-0 placeholder-label"
                          >Price($)</label
                        >
                        <p v-if="errors.price" class="invalid-message mb-0 pt-2">
                          {{errors.price}}
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                      <div class="position-relative">
                        <Field
                          type="number"
                          name="clockHours"
                          v-model="values.clockHours"
                          :class="[
                            (!values.clockHours)?'':'active',
                            'border-0 position-relative w-100',
                          ]"
                        />
                        <label for="" class="mb-0 placeholder-label"
                          >Clock Hours</label
                        >
                        <p v-if="errors.clockHours" class="invalid-message mb-0 pt-2">
                          {{errors.clockHours}}
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                      <Field name="availableUntil" v-slot="{ field }">
                      <v-date-picker
                        v-bind="field"
                        v-model="values.availableUntil"
                        :popover="{ visibility: 'focus' }"
                        :input-debounce="500"
                        class="custom-calendar position-relative single-calendar w-100 single-calendar"
                        mode="date"
                        
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            :class="[
                              (!values.availableUntil) ? '' : 'active',
                              'border-0 position-relative w-100',
                            ]"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >Available in store until</label
                          >
                        </template>
                      </v-date-picker>
                    </Field>
                    </div>
                    <div v-if="pageData.courseReqData && pageData.courseReqData.glossaries && pageData.courseReqData.glossaries.length>0" class="col-lg-6 mt-4">
                      <div class="position-relative">
                        <label
                          for=""
                          :class="[
                            `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                              (values.attachedGlossaries && values.attachedGlossaries.length ||
                              multiselectFocus[0])
                                ? 'active'
                                : ''
                            }`,
                          ]"
                          >Attached glossaries</label
                        >
                        <Field
                          name="attachedGlossaries"
                          v-model="values.attachedGlossaries"
                          v-slot="{ field }"
                        >
                          <Multiselect
                            v-bind="field"
                            v-model="values.attachedGlossaries"
                            mode="tags"
                            :multiple="true"
                            :taggable="true"
                            :searchable="true"
                            :close-on-select="false"
                            :options="pageData.courseReqData.glossaries"
                           
                            @open="multiselectFocus[0] = true"
                            @close="multiselectFocus[0] = false"
                            class="custom-multiselect border-0 position-relative w-100"
                          />
                        </Field>
                        <p v-if="errors.attachedGlossaries" class="invalid-message mb-0 pt-2">
                          {{errors.attachedGlossaries}}
                        </p>
                      </div>
                    </div>
                    <div v-if="pageData.courseReqData && pageData.courseReqData.packages && pageData.courseReqData.packages.length>0" class="col-lg-6 mt-4">
                      <div class="position-relative">
                        <label
                          for=""
                          :class="[
                            `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                              (values.assignToPackage && values.assignToPackage.length>0 || multiselectFocus[1])
                                ? 'active'
                                : ''
                            }`,
                          ]"
                          >Assign to package</label
                        >
                        <Field
                          name="assignToPackage"
                          v-model="values.assignToPackage"
                          v-slot="{ field }"
                        >
                          <Multiselect
                            v-bind="field"
                            v-model="values.assignToPackage"
                            mode="tags"
                            :multiple="true"
                            :taggable="true"
                            :searchable="true"
                            :close-on-select="false"
                            :options="pageData.courseReqData.packages"
                            @open="multiselectFocus[1] = true"
                            @close="multiselectFocus[1] = false"
                            class="custom-multiselect border-0 position-relative w-100"
                          />
                        </Field>
  
                        <p v-if="errors.assignToPackage" class="invalid-message mb-0 pt-2">
                          {{errors.assignToPackage}}
                        </p>
                      </div>
                    </div>
  
                    <!-- kasjdfjasdklfj -->
                    <div v-if="courseId && courseId!=0" class="col-12 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="file"
                          name="fileInput"
                          hidden
                          ref="singleFileInput"
                          id="fileInput"
                          v-model="values.inputFile"
                          @change="handleFileChange()"
                        />
                        <div class="uploaded-file-area position-relative w-100">
                          <i
                            v-if="values.inputFile"
                            @click="cancelUpload()"
                            class="bi bi-x-circle-fill position-absolute top-0 end-0 lh-1 fs-5 m-1"
                          ></i>
                          <label
                            for="fileInput"
                            class="upload-label upload-img bg-white d-flex justify-content-center align-items-center w-100 px-2 py-3 mb-0"
                          >
                            <!-- before-upload  -->
                            <div
                              v-if="!values.inputFile"
                              class="before-upload d-flex flex-column justify-content-center align-items-center"
                            >
                              <i class="bi bi-cloud-arrow-up-fill lh-1 fs-2"></i>
                              Upload file
                            </div>
                            <!-- after-upload  -->
                            <div v-else class="after-upload h-100 w-100">
                              <img
                                :src=" uploadedFileURL?uploadedFileURL:baseUrl+values.inputFile"
                                class="w-100 h-100 contain"
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
  
                    <div v-if="pageData.courseReqData && pageData.courseReqData.authers &&  pageData.courseReqData.authers.length>0" class="col-12 mt-4">
                      <h3 class="font-18 fw-semibold mb-0">Options</h3>
                    </div>
                    <div class="col-12">
                      <div class="position-relative mt-4">
                        <label
                          for=""
                          :class="[
                            `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                              values.auther
                                ? 'active'
                                : ''
                            }`,
                          ]"
                          >Select Auther</label
                        >
                        <Field
                          name="auther"
                          v-model="values.auther"
                          v-slot="{ field }"
                        >
                          <Multiselect
                            v-bind="field"
                            v-model="values.auther"
                            :options=" pageData.courseReqData.authers"
  
                            @open="multiselectFocus[2] = true"
                            @close="multiselectFocus[2] = false"
                            class="custom-multiselect border-0 position-relative w-100"
                          />
                        </Field>
  
                      </div>
                    </div>
                    
                      <div class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="checkbox"
                          name="autherGetsCommission"
                          id="autherGetsCommission"
                          :value="true"
                          :unchecked-value="false"
                          v-model="values.autherGetsCommission"
                          :disabled="!values.auther"
                        />
                        <label for="autherGetsCommission" class="mb-0"
                          >Auther gets commision</label
                        >
                      </div>
                    </div>
  
                    <div v-if="values.autherGetsCommission" class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <label
                          for=""
                          class="placeholder-label position-relative mb-0"
                          >Set author commission to:</label
                        >
                        <Field
                          id="authorCommission"
                          type="number"
                          name="authorCommission"
                          v-model="values.authorCommission"
                          class="border-0 position-relative w-24"
                        />
                        <span> % </span>
                      </div>
                    </div>
  
                      <div class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <label
                          for=""
                          class="placeholder-label position-relative mb-0"
                          >Percentage lesson required to pass</label
                        >
                        <Field
                          id="percentageLesson"
                          type="number"
                          name="percentageLesson"
                          v-model="values.percentageLesson"
                          class="border-0 position-relative w-24"
                        />
                        <span> % </span>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <label
                          for=""
                          class="placeholder-label position-relative mb-0"
                          >Percentage Topic required to pass</label
                        >
                        <Field
                          id="percentageTopic"
                          type="number"
                          name="percentageTopic"
                          v-model="values.percentageTopic"
                          class="border-0 position-relative w-24"
                        />
                        <span> % </span>
                      </div>
                    </div>
                   
                    <div class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="checkbox"
                          name="passFinalExam"
                          id="passFinalExam"
                          :value="true"
                          :unchecked-value="false"
                          v-model="values.passFinalExam"
                        />
                        <label for="passFinalExam" class="mb-0"
                          >Pass final exam to complete the course</label
                        >
                      </div>
                    </div>
                    <div v-if="values.passFinalExam" class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <label
                          for=""
                          class="placeholder-label position-relative mb-0"
                          >Percentage Final required to pass</label
                        >
                        <Field
                          id="finalPercent"
                          type="number"
                          name="finalPercent"
                          v-model="values.finalPercent"
                          class="border-0 position-relative w-24"
                        />
                        <span> % </span>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="checkbox"
                          name="securityQuestions"
                          id="securityQuestions"
                          :value="true"
                          :unchecked-value="false"
                          v-model="values.securityQuestions"
                        />
                        <label for="securityQuestions" class="mb-0"
                          >Use security questions</label
                        >
                      </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="checkbox"
                          name="hideTopicResults"
                          id="hideTopicResults"
                          :value="true"
                          :unchecked-value="false"
                          v-model="values.hideTopicResults"
                        />
                        <label for="hideTopicResults" class="mb-0"
                          >Hide exam/topic results</label
                        >
                      </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="checkbox"
                          name="hideFinalResults"
                          id="hideFinalResults"
                          :value="true"
                          :unchecked-value="false"
                          v-model="values.hideFinalResults"
                        />
                        <label for="hideFinalResults" class="mb-0"
                          >Hide exam final results</label
                        >
                      </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                      <div class="d-flex gap-2 align-items-center">
                        <Field
                          type="checkbox"
                          name="courseActive"
                          id="courseActive"
                          :value="true"
                          :unchecked-value="false"
                          v-model="values.courseActive"
                        />
                        <label for="courseActive" class="mb-0"
                          >Course is active</label
                        >
                      </div>
                    </div>
                    <div class="col-12 text-end modal-footer mt-4">
                      <button type="submit" class="btn btn-primary">
                        <div
                        id="add-course"
                        class="d-none spinner-border me-2"
                        role="status"
                      ></div>
                        Submit
                      </button>
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        class="btn btn-secondary"
                        ref="closeBtn"
                        @click.prevent="setIntialState(null)"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreviewModel :content="previewContent" :previousModelId="'courseModel'" @returnToPrevModel="showModal"/>
  </template>
  <script>
  import '@/assets/scss/pages/shop.scss';
  import courseServices from '@/services/courseServices';
  import '@vueform/multiselect/themes/default.css';
  import {  Field, useForm} from 'vee-validate';
  import * as Yup from 'yup';
  import { activeLoader } from '@/utils/helpers';
  import { ref,onMounted, reactive,inject,watch } from 'vue';
  import ckediter from '@/components/ckediter';
  import PreviewModel from '@/components/modals/PreviewModel';
  import Multiselect from '@vueform/multiselect';
  import '@vueform/multiselect/themes/default.css';
  import ChatGptService from '@/services/ChatGptService';
  import htmlEntityResolver from 'he';
  import { Modal } from 'bootstrap';
  export default {
    name: 'courses',
    components: {
      Field,
      ckediter,
      Multiselect,
      PreviewModel
    },
    props: {
      courseId: Number,
    },
    emits: ['getCourses','restProp'],
    setup(props,{emit}) {
      const restEditer=ref(false);
      const closeBtn= ref();
      const baseUrl=ref(process.env.VUE_APP_IMG_UPLOAD_BASE_URL);
      const notificationService = inject('$notification');
      const setEditerContent=  ref([false, false, false]);
      const previewContent=ref('');
      const pageData = reactive({
        courseReqData: '',
      });
      const schema = Yup.object().shape({
        courseTitle: Yup.string()
          .required('Title is required'),
          price:Yup.string()
          .required('price is required'),
          clockHours:Yup.string()
          .required('Clock Hours are required'),
  
  
      });
      const multiselectFocus = ref([false, false, false]);
      const { handleSubmit,values, errors,setFieldValue,resetForm } = useForm({
          initialValues: {
          courseTitle: '',
          infoPageTitle: '',
          editorCourseDescription: '',
          editorShortDescription: '',
          editorText: '',
          courseDescriptionQuery:'',
          shortDescriptionQuery:'',
          courseTextQuery:'',
          price: '',
          clockHours: '',
          availableUntil:  new Date().setMonth(new Date().getMonth() + 6),
          attachedGlossaries: [],
          assignToPackage: [],
          auther: '',
          authorCommission:10,
          autherGetsCommission: false,
          passFinalExam: false,
          percentageLesson: 0,
          percentageTopic: 0,
          securityQuestions: false,
          hideTopicResults: false,
          hideFinalResults: false,
          courseActive: false,
          finalPercent:50,
          inputFile: null,
        },
        validationSchema: schema,
      });
      const setIntialState=(message = null)=>{
        restEditer.value=true;
        emit('restProp');
        resetForm();
        closeBtn.value.click(); 
        if(message){
          notificationService.success(message); 
          emit('getCourses');
          activeLoader('dactive','add-course'); 
        }
        
      }
      const onSubmit = handleSubmit(async values => {
        activeLoader('active','add-course')
        const payload = new FormData();
        const date = new Date(values.availableUntil);
        const timestamp = Math.floor(date.getTime() / 1000);
        payload.append("course_name", values.courseTitle);
        payload.append("info_page_text", values.editorText);
        payload.append("info_page_title", values.infoPageTitle);
        payload.append("course_description", values.editorCourseDescription);
        payload.append("course_short_description", values.editorShortDescription);
        payload.append("price", values.price);
        payload.append("time_required", values.clockHours);
        payload.append("author_commission", (values.autherGetsCommission && values.auther )?values.authorCommission:0);
        payload.append("percent_lesson_required", values.percentageLesson);
        payload.append("percent_topic_required", values.percentageTopic);
        payload.append("use_security_questions", values.securityQuestions?1:0);
        payload.append("skip_exam_results", values.hideTopicResults?1:0);
        payload.append("is_active", values.courseActive?1:0);
        payload.append("expire_on", timestamp);
        payload.append("glossaries[]", values.attachedGlossaries);
        payload.append("packages[]", values.assignToPackage);
        payload.append("authors", values.auther);
        payload.append("skip_exam_final_results", values.hideFinalResults?1:0);
        payload.append("percent_required", values.passFinalExam?values.finalPercent:0);
        if(props.courseId){
          payload.append('inputFile',values.inputFile);
          await courseServices.editCourse(payload,props.courseId).then(async (response) => {
            setIntialState('Edited SuceessFully Created!');
          }).catch(e=>{
            alert(e);
          });
        }else{
          await courseServices
          .createCourse(payload)
          .then(async (response) => {
            setIntialState('Rcorde SuceessFully Created!');
            
          })
          .catch((e) => {
            alert(e);
          });
        }
      });
      const  getCourseToEdit= async (id)=>{
        await courseServices
        .getCourseToEdit(id)
        .then( (response) => {
          const packges=[];
          const glossaries=[];
         
          if(pageData.courseReqData && pageData.courseReqData.packages &&  pageData.courseReqData.packages.length>0){
            setFieldValue('courseTitle',response.data.course_name);
            setFieldValue('infoPageTitle',response.data.info_page_title);
            setFieldValue('editorCourseDescription',response.data.course_description?response.data.course_description:"<p></p>");
            setEditerContent.value[1]=!setEditerContent.value[1];
            setFieldValue('editorShortDescription',response.data.course_short_description?response.data.course_short_description:"<p></p>");
            setEditerContent.value[0]=!setEditerContent.value[0];
  
            setFieldValue('editorText',htmlEntityResolver.decode(response.data.info_page_text?response.data.info_page_text:"<p></p>"));
            setEditerContent.value[2]=!setEditerContent.value[2];
            setFieldValue('courseDescriptionQuery',"");
            setFieldValue('shortDescriptionQuery',"");
  
            setFieldValue('courseTextQuery',"");
            setFieldValue('price',response.data.price);
            setFieldValue('clockHours',response.data.time_required);
            setFieldValue('availableUntil',new Date(response.data.expire_on * 1000).toLocaleDateString());
            setFieldValue('authorCommission',response.data.author_commission);
            setFieldValue('autherGetsCommission',response.data.author_commission >0?true:false);
  
            setFieldValue('passFinalExam',response.data.percent_required >0?true:false);
            setFieldValue('percentageLesson',response.data.percent_lesson_required);
  
            setFieldValue('percentageTopic',response.data.percent_topic_required);
            setFieldValue('securityQuestions',response.data.use_security_questions==0?false:true);
  
            setFieldValue('hideTopicResults',response.data.skip_exam_results==0?false:true);
            setFieldValue('hideFinalResults',response.data.skip_exam_final_results==0?false:true);
  
            setFieldValue('courseActive',response.data.is_active==0?false:true);
            setFieldValue('finalPercent',response.data.percent_required);
  
  
            setFieldValue('inputFile',response.data.logo?response.data.logo:'');
          response.data.course_auther.map(author=>{
            setFieldValue('auther',author.user_id);
          })
          response.data.glossary_to_course.map(glossary=>{
            glossaries.push(glossary.glosary_id	);
          });
          response.data.course_to_package.map(coursePackage=>{
            packges.push(coursePackage.package_id);
          });
          setFieldValue('assignToPackage',packges);
          setFieldValue('attachedGlossaries',glossaries);
        
        }
          
       
        })
        .catch((e) => {
          alert(e);
        });
      }
      watch(
        () => props.courseId,
        async (currentValue, oldValue) => {
          console.log(props.courseId)
          if (currentValue != oldValue && currentValue != 0) {
            if (props.courseId > 0) {
              restEditer.value=false;
              getCourseToEdit(props.courseId);
            }
          }
        }
      );
      const chatGptPrompt = async (query, type,button,loader) => {
        if (query && type) {
          activeLoader('active', loader);
          activeLoader('dactive', button);
          
          await ChatGptService.chatGptCompletion(query)
            .then((response) => {
              if (
                response.choices &&
                response.choices.length > 0 &&
                response.choices[0]['message']['content']
              ) {
               
                switch (type) {
                  case 'shortDesc':
                    previewContent.value =  response.choices[0]['message']['content'];
                    setFieldValue('editorShortDescription',response.choices[0]['message']['content']);
                    setEditerContent.value[0]=!setEditerContent.value[0];
                    break;
                  case 'c-desc':
                    previewContent.value = response.choices[0]['message']['content'];
                    setFieldValue('editorCourseDescription',response.choices[0]['message']['content']);
                    setEditerContent.value[1]=!setEditerContent.value[1];
                    break;
                  case 'c-text':
                    previewContent.value = response.choices[0]['message']['content'];
                    setFieldValue('editorText',response.choices[0]['message']['content']);
                    setEditerContent.value[2]=!setEditerContent.value[2];
                    break;
                  default:
                    console.log('It is an unknown fruit');
                }
              
              }
               activeLoader('dactive', loader);
               activeLoader('active', button);
            })
            .catch((error) => {
              console.log(error);
             
            });
        }
      };
  
      const showModal = (id) => {
        Modal.getOrCreateInstance(document.getElementById(id)).show();
      };
      const setPreviewContent = (type)=>{
        previewContent.value="";
        if(type == 'c-shortDesc'){
          previewContent.value = values.editorShortDescription;
        }else if(type == 'text'){
          previewContent.value = values.editorText;
        }else if(type == 'c-desc'){
          previewContent.value = values.editorCourseDescription;
        }
      }
      const uploadedFileURL = ref(null);
  
      const cancelUpload = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.value = '';
        values.inputFile = null;
      };
  
      const handleFileChange = () => {
        if (values.inputFile.type.startsWith('image/'))
          uploadedFileURL.value = URL.createObjectURL(values.inputFile);
        else cancelUpload();
      };
      const getCoursesData = async () => {
        await courseServices
          .getCourseRelatedData()
          .then(async (response) => {
            pageData.courseReqData = response.data;
  
            pageData.courseReqData.glossaries = response.data.glossaries.map(glossary => ({
                value: glossary.id,
                label: glossary.glossary_name,
            }));
  
            pageData.courseReqData.packages = response.data.packages.map(option => ({
                value: option.id,
                label: option.package_name,
            }));
  
            pageData.courseReqData.authers = response.data.authers.map(auther => ({
                value: auther.id,
                label: auther.first_name+" "+auther.last_name,
            }));
            emit('enableCourseEdit');
          })
          .catch((e) => {
            alert(e);
          });
      };
      onMounted(() => {
        getCoursesData();
   
      });
  
  
      return {
        pageData,
        setEditerContent,
        onSubmit,
        values, 
        errors,
        showModal,
        setFieldValue,
        multiselectFocus,
        setPreviewContent,
        previewContent,
        chatGptPrompt,
        restEditer,
        closeBtn,
        handleFileChange,
        uploadedFileURL,
        cancelUpload,
        baseUrl,
        setIntialState
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '~@/assets/scss/components/upload.scss';
  </style>