<template>
    <!-- add lesson modal  -->
  
    <div class="modal fade p-0" id="addTopicModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="d-flex flex-wrap w-100 position-relative event-auto">
            <div class="card shadow-none">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left w-100">
                  <h4 class="card-title text-white">Add Topic Modal</h4>
                  <p class="card-category mb-0">Add Topic Data</p>
                </div>
              </div>
              <div class="card-body">
                <Form
                  class="custom-form mt-2"
                  @submit="submit"
                  @keydown="errors_v.clear($event.target.name)"
                  :validation-schema="schema"
                  :initialValues="form"
                  v-slot="{ errors }"
                  ref="topicForm"
                >
                  <div class="row">
                    <!-- Information Page  -->
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <Field
                          type="text"
                          name="topicTitle"
                          v-model="form.topicTitle"
                          :class="[
                            form.topicTitle == '' ? '' : 'active',
                            'border-0 position-relative w-100',
                          ]"
                        />
                        <label for="" class="mb-0 placeholder-label"
                          >Topic Title</label
                        >
                        <p v-if="errors.topicTitle" class="invalid-message mb-0 pt-2">
                          {{errors.topicTitle}}
                        </p>
                      </div>
                    </div>
                    <div class="col-12 mt-4">
                      <div class="position-relative">
                        <Field
                          type="text"
                          name="topicGptQuery"
                          v-model="form.topicGptQuery"
                          :class="[
                            form.topicGptQuery == '' ? '' : 'active',
                            'border-0 position-relative w-100',
                          ]"
                        />
                        <label for="" class="mb-0 placeholder-label"
                          >Query to Gpt</label
                        >
                        <p v-if="errors.topicGptQuery" class="invalid-message mb-0 pt-2">
                          {{errors.topicGptQuery}}
                        </p>
                      </div>
                    </div>
                    <div class="col-12 mt-4">
                    <div class="text-end modal-footer  border-top-0 rounded-0 px-0 pt-0 ">
                        <button @click.prevent="chatGptPrompt(form.topicGptQuery)" class="btn btn-primary">
                          <div
                              id="topicGptQuerybtn"
                              class="d-none spinner-border me-2"
                              role="status"
                              
                          ></div>
                          Chat GPT
                        </button>
                    </div>
                    </div>

                    <div class="col-12 mt-4">
                    <div class="position-relative">
                      <label
                        for=""
                        class="placeholder-label position-relative mb-3"
                        >Topic Description</label>
                      <Field
                        name="topicDescription"
                        v-model="form.topicDescription"
                        v-slot="{ field }"
                      >
                      <ckediter 
                      :editer-classes="'custom-ckeditor'"
                      :editer-name="'topicDescription'"
                      :editer-id="'topicDescription'"
                      :editer-value="form.topicDescription"
                      :editer-v-bind="field"
                      @update:editer-value="form.topicDescription = $event"
                      :editer-reset="restEditer"
                      :setEditerData="setEditerContent[0]"
                      />
                      </Field>
                    </div>
                    <p v-if="errors.topicDescription" class="invalid-message mb-0 pt-2">
                        {{errors.topicDescription}}
                    </p>
                    
                  </div>
                  <div class="col-12 mt-4">
                    <div class="text-end modal-footer border-bottom-gray2 border-top-0 rounded-0 px-0 pt-0 pb-4 mb-4">
                        <button type="submit" class="btn btn-primary ms-auto">
                          <div
                              id="addTopicbtn"
                              class="d-none spinner-border me-2"
                              role="status"
                          ></div>
                          Submit
                        </button>
                        <button type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#previewModel"
                        @click.prevent="setPreviewContent()"
                        class="btn btn-secondary">
                          Preview
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          class="btn btn-secondary d-none"
                          ref="closeBtn"
                        >
                          Close
                        </button>
                    </div>
                    </div>
                  </div>
                </Form>
                <TopicQuestionModel 
                  @reset="resetModel"
                  :topicContent="form.topicDescription"
                  :TopicId="TopicId"
                  :enableGptForm="enableGptForm"
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
   
  <PreviewModel :content="previewContent" :previousModelId="'addTopicModal'" @returnToPrevModel="showModal"/>
    </template>
 
    <script>
    import { ref,reactive,watch,inject} from 'vue';
    import PreviewModel from '@/components/modals/PreviewModel';
    //import htmlEntityResolver from 'he';
    import { activeLoader } from '@/utils/helpers';
    import Errors from '@/utils/Errors';
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';
    import topicServices from '@/services/topicServices';
    import ckediter from '@/components/ckediter';
    import TopicQuestionModel from '@/components/modals/TopicQuestionModel';
    import ChatGptService from '@/services/ChatGptService';
    import { Modal } from 'bootstrap';
    export default {
      name:'addLesson',
      components: {
        Form,
        Field,
        ckediter,
        TopicQuestionModel,
        PreviewModel
      
      },
      props: {
        lessonId: Number,
    },
   
      setup(props,{emit}) {
          const notificationService = inject('$notification');
          const errors_v = reactive(new Errors());
          const enableGptForm=ref(false);
          const closeBtn= ref();
          const setEditerContent=  ref([false]);
          const courseLessonId=ref(0);
          const previewContent =ref('');
          const TopicId =ref(0);
          const initialState = {
            topicTitle:'',
            topicDescription:''
          };
          const topicForm =ref(null);
          const restEditer=ref(false);
          const form = initialState;
          const multiselectFocus = ref([false, false]);
          const schema = Yup.object().shape({
              topicTitle: Yup.string().required('Title is required'),
              topicDescription: Yup.string().required('Description is required'),
          });
        
          const resetModel =(value)=>{
            if(value=='all'){
              notificationService.success("Recorde Sucessfully added!")
              topicForm.value.resetForm();
              restEditer.value=true;
              closeBtn.value.click();
              enableGptForm.value=false;
              TopicId.value =0;
            }

          };
          const setPreviewContent = ()=>{
            previewContent.value="";
            previewContent.value = form.topicDescription;
           
          }
          const showModal = (id) => {
            Modal.getOrCreateInstance(document.getElementById(id)).show();
          };
         
          const chatGptPrompt = async (query) => {
                if (query) {
                 activeLoader('active', 'topicGptQuerybtn');
                  await ChatGptService.chatGptCompletion(query)
                    .then((response) => {
                      if (
                        response.choices &&
                        response.choices.length > 0 &&
                        response.choices[0]['message']['content']
                      ) {
                        form.topicDescription = response.choices[0]['message']['content'];
                        setEditerContent.value[0]=!setEditerContent.value[0];
                      
                      }
                      activeLoader('dactive', 'topicGptQuerybtn');
                    })
                    .catch((error) => {
                      console.log(error);
                      //activeLoader('dactive', type);
                    });
                }
          };

  
          const submit = async (values)=>{
            activeLoader('active','addTopicbtn')
            const payload={
                title:values.topicTitle,
                content:values.topicDescription,
                lesson_id:courseLessonId.value
            };
            topicServices.addTopicToLesson(payload).then(async (response) => {
              if (response.data.status == 'success') {
                TopicId.value=response.data.TopicId;
                notificationService.success("Recorde Sucessfully added!")
                enableGptForm.value=true;
                activeLoader('dactive','addTopicbtn')
              }
   
            }).catch((e) => {
             alert(e);
             activeLoader('dactive','addTopicbtn')
           });
          }
          watch(
            () => props.lessonId,
            async (currentValue, oldValue) => {
               
                if (currentValue != oldValue && currentValue != 0) {
                    if(props.lessonId){
                        courseLessonId.value=props.lessonId;
                    }
            
                }
            }
        );
  
  
        return {
          schema,
          form,
          submit,
          closeBtn,
          errors_v,
          multiselectFocus,
          setEditerContent,
          enableGptForm,
          TopicId,
          topicForm,
          resetModel,
          restEditer,
          chatGptPrompt,
          showModal,
          setPreviewContent,
          previewContent,
        };
      },
    };
    </script>
    