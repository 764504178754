<template>
  <!-- add lesson modal  -->

  <div class="modal fade p-0" id="addLessonModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="d-flex flex-wrap w-100 position-relative event-auto">
          <div class="card shadow-none">
            <div
              class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
            >
              <div class="content-card-left w-100">
                <h4 class="card-title text-white">Add Lesson Modal</h4>
                <p class="card-category mb-0">Add Lesson</p>
              </div>
            </div>
            <div class="card-body">
              <Form
                class="custom-form mt-2"
                @submit="submit"
                @keydown="errors_v.clear($event.target.name)"
                :validation-schema="schema"
                :initialValues="form"
                v-slot="{ errors }"
              >
                <div class="row">
                  <!-- Information Page  -->
                  <div class="col-12">
                    <h3 class="font-18 fw-semibold mb-0">Copy Lesson</h3>
                  </div>
                  <div class="col-12">
                    <div class="position-relative mt-4">
                      <label
                        for=""
                        :class="[
                          `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                            form.copyCourseId
                              ? 'active'
                              : ''
                          }`,
                        ]"
                        >Select Course</label
                      >
                      <Field
                        name="copyCourseId"
                        v-model="form.copyCourseId"
                        v-slot="{ field }"
                      >
                        <Multiselect
                          v-bind="field"
                          v-model="form.copyCourseId"
                          :options="pageData.courses"
                          @open="multiselectFocus[0] = true"
                          @close="multiselectFocus[0] = false"
                          @Change="setLessonOptions"
                          class="custom-multiselect border-0 position-relative w-100"
                        />
                      </Field>

                    </div>
                  </div>
                  <div class="col-12">

                    <div class="position-relative mt-4">
                      <label
                        for=""
                        :class="[
                          `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                            form.copyLessonId
                              ? 'active'
                              : ''
                          }`,
                        ]"
                        >Select Lesson</label
                      >
                      <Field
                        name="copyLessonId"
                        v-model="form.copyLessonId"
                        v-slot="{ field }"
                      >
                        <Multiselect
                          v-bind="field"
                          v-model="form.copyLessonId"
                          :options="pageData.lessons"
                          @open="multiselectFocus[0] = true"
                          @close="multiselectFocus[0] = false"
                          @Change="setLessonContent"
                          class="custom-multiselect border-0 position-relative w-100"
                        />
                      </Field>
                      <p v-if="errors.copyLessonId" class="invalid-message mb-0 pt-2">
                        {{errors.copyLessonId}}
                      </p>
                    </div>
                  </div>
                    
             
                  <div class="col-12 mt-4">
                    <h3 class="font-18 fw-semibold mb-0">Lesson Data</h3>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="position-relative">
                      <Field
                        type="number"
                        name="lessonsOrder"
                        v-model="form.lessonsOrder"
                        :class="[
                          form.lessonsOrder == '' ? '' : 'active',
                          'border-0 position-relative w-100',
                        ]"
                      />
                      <label for="" class="mb-0 placeholder-label"
                        >Order of the lesson in the course</label>
                        <p v-if="errors.lessonsOrder" class="invalid-message mb-0 pt-2">
                        {{errors.lessonsOrder}}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="position-relative">
                      <Field
                        type="text"
                        name="lessonsTitle"
                        v-model="form.lessonsTitle"
                        :class="[
                          form.lessonsTitle == '' ? '' : 'active',
                          'border-0 position-relative w-100',
                        ]"
                      />
                      <label for="" class="mb-0 placeholder-label"
                        >Lesson Title</label
                      >
                      <p v-if="errors.lessonsTitle" class="invalid-message mb-0 pt-2">
                        {{errors.lessonsTitle}}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="position-relative">
                      <Field
                        name="lessonsDescription"
                        :class="[
                          form.lessonsDescription == '' ? '' : 'active',
                          'form-control border-0 position-relative w-100 py-2 mt-0',
                        ]"
                        :textContent="form.lessonsDescription"
                        v-model="form.lessonsDescription"
                        as="textarea"
                      />
                      <label for="" class="mb-1 placeholder-label"
                        >Lessons Description</label
                      >
                      <p v-if="errors.lessonsDescription" class="invalid-message mb-0 pt-2">
                        {{errors.lessonsDescription}}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 text-end modal-footer mt-5">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      class="btn btn-secondary"
                      ref="closeBtn"
                     
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </template>
  
  <script>
  import { ref,reactive,watch,onMounted} from 'vue';
  //import htmlEntityResolver from 'he';
  import Errors from '@/utils/Errors';
  import { Form, Field } from 'vee-validate';
  import * as Yup from 'yup';
  import lessonServices from '@/services/lessonServices';
  import Multiselect from '@vueform/multiselect';
  import courseServices from '@/services/courseServices';
  export default {
    name:'addLesson',
    components: {
      Form,
      Field,
      Multiselect,
      //ErrorMessage,
    },
    props: {
        courseId: Number,
    },
 
    setup(props) {
        const courseId=ref('');
        const errors_v = reactive(new Errors());
        const closeBtn= ref();
        const pageData = ref({
          courses: '',
          lessons:''
        });
        const initialState = {
            courseTitle: '',
            lessonsOrder: '',
            lessonsTitle: '',
            lessonsDescription: '',
            copyCourseId:'',
            copyLessonId:''
        };
        const form = initialState;
        const multiselectFocus = ref([false, false]);
        const schema = Yup.object().shape({
            lessonsTitle: Yup.string()
                .required('Title is required'),
            lessonsDescription: Yup.string()
            .required('Description is required'),
            copyCourseId: Yup.string()
            .nullable(),
            copyLessonId: Yup.string().test('required-if-copyCourseId', 'Lesson ID is required', function (value) {
            const copyCourseId = this.parent.copyCourseId;
           
            if (copyCourseId) {
              return !!value;
            }
            return true;
          }),
        });
        const setLessonContent =(id)=>{
          const lessonData=pageData.value.lessons
          .filter(item => item.value === id);
            form.lessonsOrder=lessonData[0].order_in_course;
            form.lessonsTitle=lessonData[0].title ;
            form.lessonsDescription=lessonData[0].description;
    
          console.log(Object(lessonData));

        }
        const setLessonOptions=(id)=>{
          pageData.value.courses.filter(item => item.value == id);
          pageData.value.lessons = pageData.value.courses
          .filter(item => item.value === id)
          .flatMap(item => item.lessons.map(lesson => ({
            ...lesson,
            value: lesson.id,
            label: lesson.title,
          })));

          
        }
        onMounted(async()=>{
          await courseServices
        .getCourses()
        .then(async (response) => {
          //pageData.courseReqData = response.data;
          pageData.value.courses = response.data.map(item => ({
              value: item.id,
              label: item.course_name,
              lessons:item.lesson
          }));

        })
        .catch((e) => {
          alert(e);
        });

        })
       
     
       

        const submit = async (values,actions)=>{
            const payload={
                course_id:courseId.value,
                title: values.lessonsTitle,
                description:values.lessonsDescription,
                order_in_course:values.lessonsOrder,
                copy_course_id:values.copyCourseId,
                copy_lesson_id:values.copyLessonId               
            };
            
            await lessonServices.addLessonToCourse(payload)
            .then(async () => {
              actions.resetForm();
              closeBtn.value.click();
          
            }).catch((e) => {
                alert(e);
            });
            console.log(payload, courseId.value,'-----------user');
        }
        watch(
            () => props.courseId,
            async (currentValue, oldValue) => {
                console.log(props.courseId,'-----------------');
                if (currentValue != oldValue && currentValue != 0) {
                    if(props.courseId){
                        courseId.value=props.courseId;
                       // emit('resetCourseId', 0);
                    }
            
                }
            }
        );


      return {
        schema,
        form,
        submit,
        closeBtn,
        errors_v,
        multiselectFocus,
        pageData,
        setLessonOptions,
        setLessonContent,

      };
    },
  };
  </script>
  