import * as API from "@/services/API";

export default {

    updateTopicContent(payload, topicId){
        return API.apiClient.put('/api/topics/'+topicId,payload);
    },
    async getAllTopics(headers,page){
        return await API.apiClient.get('/api/topics?page='+page,{ 
            headers: headers
        });
    },
    async getAllLessons(headers,page){
        return await API.apiClient.get('/api/lessons?page='+page,{ 
            headers: headers
        });
    },
    async removeLesson(lessonsId) {
        return await API.apiClient.delete(`/api/lessons/${lessonsId}`);
    },
    async addLessonToCourse(payload){
        return await API.apiClient.post('/api/lessons',payload);
    },
};
