import * as API from "@/services/API";

export default {
    async replaceTopicQuestionAnswers(payload){
        return await API.apiClient.post('/api/replace/topics/questions',payload);
    },
    async copySingleQuestionAnswers(payload){
        return await API.apiClient.post('/api/replace/single/questions',payload);
    },
    async updateTopicQuestionAnswers(payload){
        return await API.apiClient.post('/api/questions/update',payload);
    },

    getTopicQuestion(topicsId){
        return API.apiClient.get('/api/questions/'+topicsId);
    },
    async removeQuestion(QuestionId) {
        return await API.apiClient.delete(`/api/questions/delete/${QuestionId}`);
    },

    async addQuesitons(payload,parentId){
        return await API.apiClient.post('/api/questions',payload,{ 
            headers: {parent_id:parentId}
        });
    },
    async getQuesitonType(){
        return await API.apiClient.get('/api/question/types');
   },
};