import * as API from "@/services/API";

export default {
  
    async getAllPackages(page,headers){
        return await API.apiClient.get('/api/packages?page='+page,{ 
            headers: headers
        });
        
    },
    async removePackage(id) {
        return await API.apiClient.delete(`/api/packages/${id>0 && id}`);
    },
    async createPackage(payload) {
        return await API.apiClient.post(`/api/packages`,payload);
    },
    async getPackageToEdit(id){
        return await API.apiClient.get(`/api/packages/${id}`);
        
    },

    async editPackage(payload,id){
        return await API.apiClient.post(`/api/packages/${id}?_method=PUT`,payload);
        
    },
};