<template>
    <tr>
        <td colspan="100%" class="text-center">
            <span :class="loaderClasses"></span>    
        </td>
    </tr>
</template>
<script>
    export default {
    props: {
        loaderClasses: String,
    },
    setup(props) {
        return {
        }
    }
}
</script>