<template>
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">Packages</h4>
                  <p class="card-category mb-0">
                    Here you can view Package's data
                  </p>
                </div>
                <div class="content-card-right ms-auto">
                  <a
                    href="#edit"
                    data-bs-toggle="modal"
                    data-bs-target="#packageModel"
                    @click.prevent
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle"
                  >
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <div class="header-content">
                  <form
                    class="table-header mx-0 mb-1 custom-form position-sticky"
                  >
                    <div class="row m-0 align-items-center">
                      <div
                        class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-0 ms-lg-auto ms-md-auto"
                      >
                      <input
                        v-model="serarchQuery['package-name']"
                        @input="changeTitleSearch()"
                        class="custom-multiselect
                        table-header-multi h-auto" placeholder="Search package Name"     
                      />
                    </div>
                    </div>
                  </form>

                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table shop-table">
                      <thead>
                        <tr>
                          <th class="text-capitalize">Course Id</th>
                          <th class="text-capitalize">Package Title</th>
                          <th class="text-capitalize">Schools</th>
                          <th class="text-capitalize">Created on</th>
                          <th class="text-capitalize">Total Income</th>
                          <th class="text-capitalize">Status</th>
                          <th class="text-capitalize">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                        <dataLoader  v-if="
                            !pageData.allPackages && pageData.allPackages == ''
                          " :loaderClasses="'custom-animated-loader d-inline-block position-relative'" />
                        <tr
                          v-for="onePackage in pageData.allPackages.data"
                          :key="onePackage.id"
                        >
                          <td>{{ onePackage.id }}</td>
                          <td>{{ onePackage.package_name }}</td>
                          <td v-if="onePackage.schools && onePackage.schools.length>0">
                            <span class="d-block mb-2"
                              v-for="school in onePackage.schools"
                              :key="school.id"
                            >
                              {{ school.name ? school.name + ',' : '' }}</span
                            >
                          </td>
                          <td v-else>
                           {{ '---' }}
                          </td>
                          <td>{{ onePackage.created_on }}</td>
                          <td><span class="badge rounded-pill text-bg-primary d-inline-block px-2 py-1">{{ '$'+" "+onePackage.total_income??'---' }}</span></td>
                          <td><span :class="[onePackage.is_active?'text-bg-success':'text-bg-danger', 'badge rounded-pill d-inline-block px-2 py-1']"></span></td>
                          <td>
                            <span class="d-flex align-items-center gap-2">
                              <span data-bs-toggle="modal" data-bs-target="#deleteSweetConfirmation" @click.prevent="packageId=onePackage.id" class="d-inline-block pointer">
                                  <i :class="[`bi bi-trash  fs-4 text-black`]"></i>
                              </span>
                              <span data-bs-toggle="modal" data-bs-target="#packageModel" @click.prevent="packageId=onePackage.id" class="d-inline-block pointer">
                                  <i :class="[`bi bi-pencil  fs-4 text-black`]"></i>
                              </span>
                            </span>
                          </td>
                        </tr>
                        <noRecordeFound v-if="pageData.allPackages && pageData.allPackages.data && pageData.allPackages.data.length==0" />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              >
              <!-- <p>{{ pageData.allPackages.data ?? '' }}</p> -->
                <p
                  v-if="
                    pageData.allPackages &&
                    pageData.allPackages.data 
                  "
                  class="d-lg-inline-block table-data"
                >
                  Showing {{ pageData.allPackages.meta.from ?? '' }} to
                  {{ pageData.allPackages.meta.to ?? '' }} of
                  {{ pageData.allPackages.meta.total ?? '' }} entries..
                </p>
                <div
                  v-if="
                    pageData.allPackages &&
                    pageData.allPackages.data
                  "
                  class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                >
                  <v-pagination
                    class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                    v-model="paginationPage"
                    :pages="
                      pageData.allPackages ? pageData.allPackages.meta.last_page : null
                    "
                    :range-size="1"
                    @update:modelValue="getAllPackages"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <AddPackageModel
  @getPackages="getAllPackages"
  :packageId="packageId"
  />

  <sweatAlert @deleteItem="deletePackage"/>
</template>

<script>
import '@/assets/scss/pages/shop.scss';
import packageServices from '@/services/packageServices';

import { onMounted, ref, reactive,inject} from 'vue';
import dataLoader from '@/components/dataLoader';
import VPagination from '@hennge/vue3-pagination';
import sweatAlert from '@/components/sweatAlert.vue';
import '@vueform/multiselect/themes/default.css';
import debounce from 'lodash/debounce';
import AddPackageModel from '@/components/modals/AddPackageModel.vue';
import noRecordeFound from '@/components/noRecordeFound';
export default {
  name: 'courses',
  components: {
    VPagination,
    dataLoader,
    noRecordeFound,
    sweatAlert,
    AddPackageModel
  },
  setup() {
    const notificationService = inject('$notification');
    const paginationPage = ref(1);
    const packageId=ref(0);
    const pageData = reactive({
      allPackages: '',
    });
    const serarchQuery = {
           'package-name':''
    };
    const courseId=ref(0);
    const getAllPackages = async (val = null) => {
      packageId.value=0;
      pageData.allPackages = '';
      await packageServices
        .getAllPackages(val ? val : 1,serarchQuery)
        .then(async (response) => {
          pageData.allPackages = response.data;
        })
        .catch((e) => {
          alert(e);
        });
    };

    const deletePackage = async (value,closeBtn)=>{
         if(value && packageId){
            await packageServices
            .removePackage(packageId.value)
            .then(async (response) => {
               if(response.status==200){
                notificationService.success('Rcorde SuceessFully Deleted!');
                  getAllPackages();
               }
            })
            .catch((e) => {
               alert(e);
            });
         }
        closeBtn.value.click();
       }
    onMounted(() => {
     
      getAllPackages();
    });
    const changeTitleSearch = debounce(async () => {
      getAllPackages();
    }, 1000)
    

    return {
      getAllPackages,
      paginationPage,
      pageData,
      serarchQuery,
      changeTitleSearch,
      courseId,
      deletePackage,
      packageId
    };
  },
};
</script>
