<template>
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">Courses</h4>
                  <p class="card-category mb-0">
                    Here you can view course's data
                  </p>
                </div>
                <div class="content-card-right ms-auto">
                  <a
                    href="#edit"
                    data-bs-toggle="modal"
                    data-bs-target="#courseModel"
                    @click.prevent
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle"
                  >
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <div class="header-content">
                  <form
                    class="table-header mx-0 mb-1 custom-form position-sticky"
                  >
                    <div class="row m-0 align-items-center">
                      <div
                        class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-0 ms-lg-auto ms-md-auto"
                      >
                      <input
                        v-model="serarchQuery['course-name']"
                        @input="changeTitleSearch()"
                        class="custom-multiselect
                        table-header-multi h-auto" placeholder="Search course Name"     
                      />
                    </div>
                    </div>
                  </form>

                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table shop-table">
                      <thead>
                        <tr>
                          <th class="text-capitalize">Course Id</th>
                          <th class="text-capitalize">Course Title</th>
                          <th class="text-capitalize">Version</th>
                          <th class="text-capitalize">Schools</th>
                          <th class="text-capitalize">Created on</th>
                          <th class="text-capitalize">Author Name</th>
                          <th class="text-capitalize">Status</th>
                          <th class="text-capitalize text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                       
                        <dataLoader  v-if="
                            !pageData.allCourses && pageData.allCourses == ''
                          " :loaderClasses="'custom-animated-loader d-inline-block position-relative'" />
                        <tr
                          v-for="course in pageData.allCourses.data"
                          :key="course.id"
                        >
                          <td>{{ course.id }}</td>
                          <td>{{ course.course_name }}</td>
                          <td><span class="badge rounded-pill text-bg-primary d-inline-block px-2 py-1">{{ course.version_number }}</span></td>
                          <td v-if="course.schools && course.schools.length>0">
                            <span class="d-block mb-2"
                              v-for="school in course.schools"
                              :key="school.id"
                            >
                              {{ school.name ? school.name + ',' : '' }}</span
                            >
                          </td>
                          <td v-else>
                           {{ '---' }}
                          </td>
                          <td>{{ course.created_on }}</td>
                          <td>{{ course.author_full_name?course.author_full_name:'---' }}</td>
                          <td><span :class="[course.is_active?'text-bg-success':'text-bg-danger', 'badge rounded-pill d-inline-block px-2 py-1']"></span></td>
                          <!-- <td>{{ course.course_is_active?course.course_is_active:'---' }}</td> -->
                          <td>
                            <span class="d-flex align-items-center gap-2">
                              <router-link
                              :to="'/lessons/' + course.id"
                              class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle border-0"
                             
                              >
                              <i
                                class="bi bi-eye-fill text-primary lh-1 fs-5 pointer"
                              >
                              </i>
                            </router-link>
                            <button
                              v-if="enableEditCourse"
                              class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle border-0"
                              href="#edit"
                              data-bs-toggle="modal"
                              data-bs-target="#courseModel"
                              @click.prevent="courseId  = course.id"
                            
                              >
                              <i  
                                class="bi bi-pencil text-primary lh-1 fs-5 pointer"
                              >
                              </i>
                            </button>

                              <!-- <a
                                href="#lesson"
                                data-bs-toggle="modal"
                                data-bs-target="#addLessonModal"
                                class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle border-0"
                                @click.prevent="courseId = course.id"
                              >
                                 <span
                                  data-bs-toggle="tooltip"
                                  data-bs-title="view"
                                >
                                  Add Lesson
                                </span> 
                                <i
                                role="button"
                                data-bs-toggle="tooltip"
                                data-bs-title="Add Lesson"
                                class="bi bi-plus-square-fill text-primary lh-1 fs-5"
                              ></i>

                              </a> -->
                            </span>
                          
                          </td>
                        </tr>
                        <noRecordeFound v-if="pageData.allCourses && pageData.allCourses.data && pageData.allCourses.data.length==0" />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              >
              <!-- <p>{{ pageData.allCourses.data ?? '' }}</p> -->
                <p
                  v-if="
                    pageData.allCourses &&
                    pageData.allCourses.data 
                  "
                  class="d-lg-inline-block table-data"
                >
                  Showing {{ pageData.allCourses.from ?? '' }} to
                  {{ pageData.allCourses.to ?? '' }} of
                  {{ pageData.allCourses.total ?? '' }} entries..
                </p>
                <div
                  v-if="
                    pageData.allCourses &&
                    pageData.allCourses.data
                  "
                  class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                >
                  <v-pagination
                    class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                    v-model="paginationPage"
                    :pages="
                      pageData.allCourses ? pageData.allCourses.last_page : null
                    "
                    :range-size="1"
                    @update:modelValue="getAllCourses"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddEditCoursesModel
      :course-id="courseId"
      @getCourses="getAllCourses()"
      @restProp="courseId =0"
      @enableCourseEdit="enableEditCourse=true"
      />
    <AddLessonModel 
    v-if="false"
      :course-id="courseId"
      @resetCourseId="()=>courseId = ''"
    />
    </section>
  </div>


 
</template>

<script>
import '@/assets/scss/pages/shop.scss';
import courseServices from '@/services/courseServices';
import { onMounted, ref, reactive } from 'vue';
import dataLoader from '@/components/dataLoader';
import VPagination from '@hennge/vue3-pagination';
import '@vueform/multiselect/themes/default.css';
import debounce from 'lodash/debounce';
import AddEditCoursesModel from '@/components/modals/AddEditCoursesModel.vue';
import AddLessonModel from '@/components/modals/AddLessonModel';
import noRecordeFound from '@/components/noRecordeFound';
export default {
  name: 'courses',
  components: {
    VPagination,
    dataLoader,
    AddEditCoursesModel,
    AddLessonModel,
    noRecordeFound
  },
  setup() {
    const paginationPage = ref(1);
    const pageData = reactive({
      allCourses: '',
    });
    const serarchQuery = {
           'course-name':''
    };
    const enableEditCourse=ref(false);
    const courseId=ref(0);
    const getAllCourses = async (val = null) => {
      pageData.allCourses = '';
      await courseServices
        .getAllCourses(val ? val : 1,serarchQuery)
        .then(async (response) => {
          pageData.allCourses = response.data;
        })
        .catch((e) => {
          alert(e);
        });
    };

    onMounted(() => {
     
      getAllCourses();
    });
    const changeTitleSearch = debounce(async () => {
      getAllCourses();
    }, 1000)


    return {
      getAllCourses,
      paginationPage,
      pageData,
      serarchQuery,
      changeTitleSearch,
      courseId,
      enableEditCourse
    };
  },
};
</script>
