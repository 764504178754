import * as API from "@/services/API";

export default {
    async getAllCourses(page,headers){
        return await API.apiClient.get('/api/courses?page='+page,{ 
            headers: headers
        });
    },
    async getCourseRelatedData(){
        return await API.apiClient.get('/api/courses/related/data');
    },

    async createCourse(payload){
        return await API.apiClient.post('/api/courses',payload);
    },
    async getCourses(){
        return await API.apiClient.get('/api/get/courses');
    },
    async getCourseToEdit(id){
        return await API.apiClient.get(`/api/courses/${id}/edit`);
    },
    async editCourse(payload,id){
        return await API.apiClient.post(`/api/courses/${id}?_method=PUT`,payload);
    },
};
