<template>
  <div class="modal fade p-0" id="packageModel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="d-flex flex-wrap w-100 position-relative event-auto">
        <div class="card shadow-none">
          <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
            <div class="content-card-left w-100">
              <h4 class="card-title text-white">Package Modal</h4>
              <p class="card-category mb-0">{{packageId?'Edit Package information':'Add Package information'}}</p>
            </div>
          </div>
          <div class="card-body">
            <form
                class="custom-form mt-2"
                @submit="submit"
              >
              <div class="col-12 mt-4">
                    <div class="position-relative">
                      <Field
                        type="text"
                        name="package_name"
                        v-model="values.package_name"
                        :class="[
                          values.package_name == '' ? '' : 'active',
                          'border-0 position-relative w-100',
                        ]"
                      />
                      <label for="" class="mb-0 placeholder-label"
                        >Title</label
                      >
                      <p v-if="errors.package_name" class="invalid-message mb-0 pt-2">
                        {{errors.package_name}}
                      </p>
                    </div>
              </div>
               <div class="col-12 mt-4">
                    <div class="position-relative">
                      <Field
                        type="text"
                        name="short_description"
                        v-model="values.short_description"
                        :class="[
                          values.short_description == '' ? '' : 'active',
                          'border-0 position-relative w-100',
                        ]"
                        as="textarea"
                      />
                      <label for="" class="mb-0 placeholder-label"
                        >Short Description</label
                      >
                      <p v-if="errors.short_description" class="invalid-message mb-0 pt-2">
                        {{errors.short_description}}
                      </p>
                    </div>
              </div>
              <div class="col-12 mt-4">
                    <div class="position-relative">
                      <Field
                        type="text"
                        name="description"
                        v-model="values.description"
                        :class="[
                          values.description == '' ? '' : 'active',
                          'border-0 position-relative w-100',
                        ]"
                        as="textarea"
                      />
                      <label for="" class="mb-0 placeholder-label"
                        >Description</label
                      >
                      <p v-if="errors.description" class="invalid-message mb-0 pt-2">
                        {{errors.description}}
                      </p>
                    </div>
              </div>
              <div class="col-12 mt-4">
                    <div class="position-relative">
                    
                      <Field
                        type="text"
                        name="price"
                        v-model="values.price"
                        :class="[
                          values.price == '' ? '' : 'active',
                          'border-0 position-relative w-100',
                        ]"
                      />
                      <label for="" class="mb-0 placeholder-label"
                        >Price</label
                      >
                      <p v-if="errors.price" class="invalid-message mb-0 pt-2">
                        {{errors.price}}
                      </p>
                    </div>
              </div>
              <div  class="col-lg-6 mt-4">
                    
                    <div class="position-relative">
                      <label
                        for=""
                        :class="[
                          `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                            (values.courses && values.courses.length>0 || multiselectFocus[0])
                              ? 'active'
                              : ''
                          }`,
                        ]"
                        >Add course to this package</label
                      >
                      <Field
                        name="courses"
                        v-model="values.courses"
                        v-slot="{ field }"
                      >
                        <Multiselect
                          v-bind="field"
                          v-model="values.courses"
                          mode="tags"
                          :multiple="true"
                          :taggable="true"
                          :searchable="true"
                          :close-on-select="false"
                          :options="pageData.courseReqData"
                          @open="multiselectFocus[0] = true"
                          @close="multiselectFocus[0] = false"
                          class="custom-multiselect border-0 position-relative w-100"
                        />
                      </Field>

                      <p v-if="errors.courses" class="invalid-message mb-0 pt-2">
                        {{errors.courses}}
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                      <Field
                        type="checkbox"
                        name="order_required"
                        id="order_required"
                        :value="1"
                        :unchecked-value="0"
                        v-model="values.order_required"
                      />
                      <label for="order_required" class="mb-0"
                        >Student must take the courses in the package order</label
                      >
                    </div>
                  </div>

                  <div class="col-lg-6 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                      <Field
                        type="checkbox"
                        name="is_active"
                        id="is_active"
                        :value="1"
                        :unchecked-value="0"
                        v-model="values.is_active"
                      />
                      <label for="is_active" class="mb-0"
                        >Course is active</label
                      >
                    </div>
                  </div>
                  <div v-if="packageId"  class="col-12 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                      <Field
                        type="file"
                        name="fileInput"
                        hidden
                        ref="singleFileInput"
                        id="fileInput"
                        v-model="values.fileInput"
                        @change="handleFileChange()"
                      />
                      <div class="uploaded-file-area position-relative w-100">
                        <i
                          v-if="values.fileInput"
                          @click="cancelUpload()"
                          class="bi bi-x-circle-fill position-absolute top-0 end-0 lh-1 fs-5 m-1"
                        ></i>
                        <label
                          for="fileInput"
                          class="upload-label upload-img bg-white d-flex justify-content-center align-items-center w-100 px-2 py-3 mb-0"
                        >
                          <!-- before-upload  -->
                          <div
                            v-if="!values.fileInput"
                            class="before-upload d-flex flex-column justify-content-center align-items-center"
                          >
                            <i class="bi bi-cloud-arrow-up-fill lh-1 fs-2"></i>
                            Upload file
                          </div>
                          <!-- after-upload  -->
                          <div v-else class="after-upload h-100 w-100">
                            <img
                              :src="uploadedFileURL?uploadedFileURL:baseUrl+values.fileInput"
                              class="w-100 h-100 contain"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
              <div class="col-12 text-end modal-footer mt-md-0 mt-4">
              <button type="button" data-bs-dismiss="modal" class="btn btn-secondary" ref="closeBtn">
                Close
              </button>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
            </form>

           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field,useForm } from 'vee-validate';
import { ref, reactive,  onMounted,inject,watch } from 'vue';
import * as Yup from 'yup';
import packageServices from '@/services/packageServices';
import courseServices from '@/services/courseServices';
import Multiselect from '@vueform/multiselect';
export default {
  name: 'AddPackageModel',
  components: {
    Field,
    Multiselect
  },
  props:{
    packageId:Number
  },
  setup(props, { emit }) {
    const notificationService = inject('$notification');
    const baseUrl=ref(process.env.VUE_APP_IMG_UPLOAD_BASE_URL)
    const pageData = reactive({
      courseReqData: '',
    });
    const multiselectFocus = ref([false, false, false]);
    const closeBtn= ref();
    onMounted(async()=>{
          await courseServices
        .getCourses()
        .then(async (response) => {
          pageData.courseReqData =response.data.data;
        })
        
        .catch((e) => {
          alert(e);
        });

        });
       
    const schema = Yup.object().shape({
      package_name: Yup.string().required('Title is required'),
      price:Yup.number().required('Price is required'),
      short_description:Yup.string().required('Short description is required'),
      description:Yup.string().required('Description is required'),
      courses:Yup.array().nullable().min(1, "select at least one course")

    });
    const { handleSubmit,values, errors,setFieldValue,resetForm } = useForm({
        initialValues: {
          package_name: '',
          price:0,
          short_description:'',
          description:'',
          courses:[],
          order_required:0,
          is_active:0,
          fileInput:''

      },
      validationSchema: schema,
    });
    const uploadedFileURL = ref(null);
        const cancelUpload = () => {
          const fileInput = document.getElementById('fileInput');
          fileInput.value = '';
          values.fileInput = null;
        };
      const handleFileChange = () => {
        if (values.fileInput.type.startsWith('image/'))
          uploadedFileURL.value = URL.createObjectURL(values.fileInput);
        else cancelUpload();
      };
    const submit = handleSubmit(async values => {
      if(props.packageId){
        const payload = new FormData();
        payload.append("package_name", values.package_name);
        payload.append("price", values.price);
        payload.append("description", values.description);
        payload.append("courses", JSON.stringify(values.courses));
        payload.append("short_description", values.short_description);
        payload.append("is_active", values.is_active);
        payload.append("order_required", values.order_required);
        payload.append("inputFile", values.fileInput);
        packageServices.editPackage(payload,props.packageId).then(res=>{
          if(res.status==200){
            resetForm();
            closeBtn.value.click();
            notificationService.success('Rcorde SuceessFully Updated!');
            emit("getPackages");
          }
        }).catch(e=>{
          alert(e);
        });
      }else{
        packageServices.createPackage(values).then(res=>{
          notificationService.success('Rcorde SuceessFully Created!');
            resetForm();
            closeBtn.value.click();
            emit("getPackages");

        }).catch(e=>{
          alert(e);
        });
      }
    });
    const  getPackageToEdit = async ()=>{
      await packageServices
      .getPackageToEdit(props.packageId)
      .then(async (response) => {
          if(response.status==200){
            values.package_name=response.data.package_name;
            values.price=response.data.price;
            values.short_description=response.data.short_description;
            values.description=response.data.description;
            values.courses=response.data.course_ids;
            values.order_required=response.data.order_required;
            values.is_active=response.data.is_active;
            values.fileInput=response.data.logo;
          }
      })
      .catch((e) => {
          alert(e);
      });
    }
    watch(
      () => props.packageId,
      async (currentValue, oldValue) => {
        if (currentValue != oldValue && currentValue != 0) {
          if (props.packageId > 0) {
            getPackageToEdit();
          } 
        }
      }
    );
    return {
      values, 
      errors,
      submit,
      schema,
      pageData,
      multiselectFocus,
      closeBtn,
      cancelUpload,
      handleFileChange,
      uploadedFileURL,
      baseUrl
    };
  },
};
</script>
