<template>
    <Form
    v-if="enableGptForm"
        @submit="getGPTQuestion"
        @keydown="errors_v.clear($event.target.name)"
        :validation-schema="schema"
        :initial-values="form"
        :initialValues="form"
        v-slot="{ errors}"
        ref="gptForm"
        class="custom-form"
        >
        <div class="row">
        <div class="col-lg-6 mt-4">
        <div class="position-relative">
          <label
            for=""
            :class="[
              form.question_type == '' ? '' : 'active',
              `active placeholder-label multiselect-placeholder pe-none ps-0 mb-0`,
            ]"
            >Question Type</label
          >
          <Field
            name="question_type"
            v-model="form.question_type"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="form.question_type"
              :options="typeOptions"
              class="custom-multiselect border-0 position-relative w-100"
            />
          </Field>
        </div>
        <p v-if="errors.question_type" class="invalid-message mb-0 pt-2">
                {{errors.question_type}}
            </p>
      </div>
      <div class="col-lg-6 mt-4">
        <div class="position-relative">
          <Field
            name="totalQuestions"
            v-model="form.totalQuestions"
            type="number"
            :class="[
              form.totalQuestions == '' ? '' : 'active',
              `active border-0 position-relative w-100`,
            ]"
          />
          <label for="" class="mb-0 placeholder-label">No of Questions</label>
          <p v-if="errors.totalQuestions" class="invalid-message mb-0 pt-2">
                {{errors.totalQuestions}}
            </p>
        </div>
      </div>

      <div v-if="!form.query" class="col-lg-6 mt-4">
        <div  class="position-relative">
          <Field
            name="query"
            v-model="form.query"
            type="text"
            :class="['active border-0 position-relative w-100']"
            disabled
          />
          <label for="" class="mb-0 placeholder-label">Query</label>
          <p v-if="errors.query" class="invalid-message mb-0 pt-2">
                {{errors.query}}
            </p>
        </div>
      </div>
      <div class="col-lg-6 mt-4">
      
        <button type="submit" class="btn btn-dark w-100">
            <div
            id="getGptQuestions"
            class="d-none spinner-border me-2"
            role="status"
        ></div>    
        Get Questions</button>
      </div>
        </div>
    </Form>
    <!-- ----------------------------------------------------- -->
    <form v-if="enableQuestionForm" @submit="onSubmit" novalidate class="custom-form">
      <div v-for="(field, idx) in TopicQuestion" :key="'arr_'+field.key+idx" class="row">
        <div class="col-12 mt-4">
        <div class="d-flex gap-2 justify-content-end">
          <span
            @click="TopicQuestion.push({ question: '', is_active: 0, feedback_error: '', feedback_correct: '', use_for_exam: 0, use_for_quiz: 0, use_for_lesson: 0, use_for_static_01: 0, use_for_static_02: 0, question_type: '',topic_id:TopicId, Answers: [] })"
            data-bs-toggle="tooltip"
            data-bs-title="Add Questions"
            role="button"
          >
            <i class="bi bi-plus-circle-fill fs-3"></i>
          </span>
          <span
            v-if="idx!=0"
            @click="TopicQuestion.splice(idx, 1)"
            data-bs-toggle="tooltip"
            data-bs-title="Remove Questions"
            role="button"
          >
            <i class="bi bi-dash-circle-fill text-danger fs-3"></i>
          </span>
        </div>
      </div>
        <div class="col-12 mt-4">
            <div class="position-relative">
            <Field :name="`TopicQuestion[${idx}].question`" 
            v-model="TopicQuestion[idx].question"
            type="text" label="Question" as="textarea" :class="[
               TopicQuestion[idx].question == '' ? '' : 'active',
                'border-0 position-relative w-100',
                ]"/>
            <label for="" class="mb-0 placeholder-label"
                >Topic Question</label
            >
            <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].question`] }}</p>

            </div>
        </div>
        <div class="d-none col-12 mt-4">
            <div class="position-relative">
            <Field :name="`TopicQuestion[${idx}].topic_id`" 
            v-model="TopicQuestion[idx].topic_id"
            type="text" label="Question" :class="[
               TopicQuestion[idx].question == '' ? '' : 'active',
                'border-0 position-relative w-100',
                ]"/>
            <label for="" class="mb-0 placeholder-label"
                >Topic id</label
            >
            <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].topic_id`] }}</p>

            </div>
        </div>
        <div class="col-lg-6 mt-4">
            <div class="d-flex gap-2 align-items-center">
                <Field
                type="checkbox"
                :name="`TopicQuestion[${idx}].is_active`"
                :id="`TopicQuestion[${idx}].is_active`"
                v-model="TopicQuestion[idx].is_active"
                :value="1"
                :unchecked-value="0"
                
                />
                <label for="courseActive" class="mb-0"
                >is Active</label
                >
               
            </div>
            <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].is_active`] }}</p>
        </div>
        <div class="col-12 mt-4">
            <div class="position-relative">
          
            <Field :name="`TopicQuestion[${idx}].feedback_error`" type="text" label="Question"
            v-model="TopicQuestion[idx].feedback_error"
            as="textarea" :class="[
              
                'active border-0 position-relative w-100',
                ]"/>
            <label for="" class="mb-0 placeholder-label"
                >Incorrect Response Message</label
            >
            <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].feedback_error`] }}</p>
            </div>
        </div>
        <div class="col-12 mt-4">
            <div class="position-relative">
          
            <Field :name="`TopicQuestion[${idx}].feedback_correct`" 
            v-model="TopicQuestion[idx].feedback_correct"
            type="text" as="textarea" :class="[
              
                'active border-0 position-relative w-100',
                ]"/>
            <label for="" class="mb-0 placeholder-label"
                >Correct Response Message</label
            >
            <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].feedback_correct`] }}</p>
            </div>
        </div>

        <div class="col-lg-6 mt-4">
            <div class="d-flex gap-2 align-items-center">
                <Field
                type="checkbox"
                :name="`TopicQuestion[${idx}].use_for_exam`"
                :id="`TopicQuestion[${idx}].use_for_exam`"
                v-model="TopicQuestion[idx].use_for_exam"
                :value="1"
                :unchecked-value="0"
                
                />
                <label for="courseActive" class="mb-0"
                >Is Course Exam Question?</label
                >
            </div>
        </div>
        <div class="col-lg-6 mt-4">
            <div class="d-flex gap-2 align-items-center">
                <Field
                type="checkbox"
                :name="`TopicQuestion[${idx}].use_for_quiz`"
                :id="`TopicQuestion[${idx}].use_for_quiz`"
                v-model="TopicQuestion[idx].use_for_quiz"
                :value="1"
                :unchecked-value="0"
                
                />
                <label for="courseActive" class="mb-0"
                >Is Topic Quiz Question?</label
                >
            </div>
        </div>

        <div class="col-lg-6 mt-4">
            <div class="d-flex gap-2 align-items-center">
                <Field
                type="checkbox"
                :name="`TopicQuestion[${idx}].use_for_lesson`"
                :id="`TopicQuestion[${idx}].use_for_lesson`"
                v-model="TopicQuestion[idx].use_for_lesson"
                :value="1"
                :unchecked-value="0"
                
                />
                <label for="courseActive" class="mb-0">Is Lesson Exam Question?</label>

                
            </div>
            <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].use_for_lesson`] }}</p>
        </div>
        <div class="col-lg-6 mt-4">
            <div class="d-flex gap-2 align-items-center">
                <Field
                type="checkbox"
                :name="`TopicQuestion[${idx}].use_for_static_01`"
                :id="`TopicQuestion[${idx}].use_for_static_01`"
                v-model="TopicQuestion[idx].use_for_static_01"
                :value="1"
                :unchecked-value="0"
                
                />
                <label for="courseActive" class="mb-0">Is Static Question 1?</label>
               

            </div>
            <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].use_for_static_01`] }}</p>
        </div>
        <div class="col-lg-6 mt-4">
            <div class="d-flex gap-2 align-items-center">
                <Field
                type="checkbox"
                :name="`TopicQuestion[${idx}].use_for_static_02`"
                :id="`TopicQuestion[${idx}].use_for_static_02`"
                v-model="TopicQuestion[idx].use_for_static_02"
                :value="1"
                :unchecked-value="0"
                
                />
                <label for="courseActive" class="mb-0"
                >Is Static Question 2?</label>
              
            </div>
            <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].use_for_static_02`] }}</p>
        </div>
        <div class="col-12 mt-4"> 
            <div class="position-relative">
                <label
                for=""
                :class="[`active placeholder-label multiselect-placeholder pe-none ps-0 mb-0`]"
                >Select Question Type </label>
                <Field
                :name="`TopicQuestion[${idx}].question_type`"
                v-model="TopicQuestion[idx].question_type"
                v-slot="{ field }"
                >
                <Multiselect
                    v-bind="field"
                    v-model="TopicQuestion[idx].question_type"
                    :options="typeOptions"
                    class="custom-multiselect border-0 position-relative w-100"
                    :key="`TopicQuestion[${idx}].question_type`"
                />

                </Field>
                <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].question_type`] }}</p>
                <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers`] }}</p>
            
            </div>
        </div>
       
        <template v-if="field.question_type === 1">
            <div class="col-12 d-flex flex-wrap justify-content-end gap-2 mt-4">
                <div class="d-flex gap-2 justify-content-end">
                    <span
                    @click="pushAnswer(idx)"
                    data-bs-toggle="tooltip"
                    data-bs-title="Add Answer"
                    role="button"
                    >
                    <i class="bi bi-plus-circle-fill fs-3"></i>
                    </span>
                </div>
            </div>
            <template v-for="(answer, answerIndex) in TopicQuestion[idx].Answers" :key="'m_'+answer.id+answerIndex">
                <div class="col-12 d-flex flex-wrap justify-content-end gap-2 mt-4">
                <div class="d-flex gap-2 justify-content-end">
         
                    <span
                    v-if="answerIndex !=0"
                    @click="removeAnswer(idx,answerIndex)"
                    data-bs-toggle="tooltip"
                    data-bs-title="Remove Answer"
                    role="button"
                    >
                    <i class="bi bi-dash-circle-fill text-danger fs-3"></i>
                    </span>
                </div>
            </div>
                <div class="col-lg-6 mt-4">
                    <div class="position-relative">
                    <Field :name="`TopicQuestion[${idx}].Answers[${answerIndex}].answer`" 
                    v-model="TopicQuestion[idx].Answers[answerIndex].answer"
                    type="text"  :class="[
                    
                        'active border-0 position-relative w-100',
                        ]"/>
                    <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].answer`" class="mb-0 placeholder-label"
                        >Answer</label
                    >
                    <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].answer`] }}</p>

                    </div>
                </div>
                <div class="col-lg-6 mt-4">
                    <div class="position-relative">
                
                    <Field :name="`TopicQuestion[${idx}].Answers[${answerIndex}].points`" 
                    v-model="TopicQuestion[idx].Answers[answerIndex].points"
                    type="number"  :class="[
                    
                        'active border-0 position-relative w-100',
                        ]"/>
                    <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].points`" class="mb-0 placeholder-label">Points</label>
                    <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].points`] }}</p>

                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                        <Field
                        type="checkbox"
                        :name="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`"
                        :id="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`"
                        v-model="TopicQuestion[idx].Answers[answerIndex].correct"
                        :value="1"
                        :unchecked-value="0"
                        
                        />
                        <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`" class="mb-0">Correct</label>
                        <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].correct`] }}</p>

                    </div>
                </div>
              
            </template>
 

            <!-- <button type="button" @click="remove(idx)">Remove Question</button> -->
        </template>





        <template v-if="field.question_type === 3">
            <div class="col-12 d-flex flex-wrap justify-content-end gap-2 mt-4">
                <div class="d-flex gap-2 justify-content-end">
                    <span
                    @click="pushAnswer(idx)"
                    data-bs-toggle="tooltip"
                    data-bs-title="Add Answer"
                    role="button"
                    >
                    <i class="bi bi-plus-circle-fill fs-3"></i>
                    </span>
                </div>
            </div>
            <template v-for="(answer, answerIndex) in TopicQuestion[idx].Answers" :key="'f_'+answer.id+answerIndex">
                <div class="col-12 d-flex flex-wrap justify-content-end gap-2 mt-4">
                <div class="d-flex gap-2 justify-content-end">
         
                    <span
                    v-if="answerIndex !=0"
                    @click="removeAnswer(idx,answerIndex)"
                    data-bs-toggle="tooltip"
                    data-bs-title="Remove Answer"
                    role="button"
                    >
                    <i class="bi bi-dash-circle-fill text-danger fs-3"></i>
                    </span>
                </div>
            </div>
                <div class="col-lg-6 mt-4">
                    <div class="position-relative">
                    <Field :name="`TopicQuestion[${idx}].Answers[${answerIndex}].answer`"
                    v-model="TopicQuestion[idx].Answers[answerIndex].answer"
                    type="text"  :class="[
                    
                        'active border-0 position-relative w-100',
                        ]"/>
                    <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].answer`" class="mb-0 placeholder-label"
                        >Answer</label
                    >
                    <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].answer`] }}</p>

                    </div>
                </div>
                <div class="col-lg-6 mt-4">
                    <div class="position-relative">
                
                    <Field :name="`TopicQuestion[${idx}].Answers[${answerIndex}].points`"
                    v-model="TopicQuestion[idx].Answers[answerIndex].points"
                    type="number"  :class="[
                    
                        'active border-0 position-relative w-100',
                        ]"/>
                    <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].points`" class="mb-0 placeholder-label">Points</label>
                    <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].points`] }}</p>

                    </div>
                </div>

                <div class="col-12 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                        <Field
                        type="checkbox"
                        :name="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`"
                        :id="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`"
                        v-model="TopicQuestion[idx].Answers[answerIndex].correct"
                        :value="1"
                        :unchecked-value="0"
                        
                        />
                        <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`" class="mb-0">Correct</label>
                        <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].correct`] }}</p>

                    </div>
                </div>
            </template>
        </template>


        <!-- for tureORfalse -->

        <template v-if="field.question_type === 2">
            <div class="col-12 d-flex flex-wrap justify-content-end gap-2 mt-4">
                    <div class="d-flex gap-2 justify-content-end">
                        <span
                        @click="pushAnswer(idx)"
                        data-bs-toggle="tooltip"
                        data-bs-title="Add Answer"
                        role="button"
                        >
                        <i class="bi bi-plus-circle-fill fs-3"></i>
                        </span>
                   
                    </div>
            </div>
            <template v-for="(answer, answerIndex) in TopicQuestion[idx].Answers" :key="'t_'+answer.id+answerIndex">
                <div class="col-12 d-flex flex-wrap justify-content-end gap-2 mt-4">
                    <div class="d-flex gap-2 justify-content-end">
                
                        <span
                        v-if="answerIndex !=0"
                        @click="removeAnswer(idx,answerIndex)"
                        data-bs-toggle="tooltip"
                        data-bs-title="Remove Answer"
                        role="button"
                        >
                        <i class="bi bi-dash-circle-fill text-danger fs-3"></i>
                        </span>
                    </div>
                </div>
                <div class="col-lg-6 mt-4">
                    <div class="position-relative">
                    <Field :name="`TopicQuestion[${idx}].Answers[${answerIndex}].answer`" type="text"
                    v-model="TopicQuestion[idx].Answers[answerIndex].answer"
                    :class="[
                    
                        'active border-0 position-relative w-100',
                        ]"/>
                    <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].answer`" class="mb-0 placeholder-label"
                        >Answer Lable</label
                    >
                    <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].answer`] }}</p>

                    </div>
                </div>
                <div class="col-lg-6 mt-4">
                    <div class="position-relative">
                
                    <Field :name="`TopicQuestion[${idx}].Answers[${answerIndex}].points`"  
                    v-model="TopicQuestion[idx].Answers[answerIndex].points"
                    type="number"  :class="[
                    
                        'active border-0 position-relative w-100',
                        ]"/>
                    <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].points`" class="mb-0 placeholder-label">Points</label>
                    <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].points`] }}</p>

                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                        <Field
                        type="checkbox"
                        :name="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`"
                        :id="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`"
                        v-model="TopicQuestion[idx].Answers[answerIndex].correct"
                        :value="1"
                        :unchecked-value="0"
                        
                        />
                        <label :for="`TopicQuestion[${idx}].Answers[${answerIndex}].correct`" class="mb-0">Correct</label>
                        <p class="invalid-message mb-0 pt-2">{{ errors[`TopicQuestion[${idx}].Answers[${answerIndex}].correct`] }}</p>

                    </div>
                </div>
             
            </template>
        </template>
      </div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="text-end modal-footer px-0">
            <div
                id="saveQuestions"
                class="d-none spinner-border me-2"
                role="status"
            ></div>
          <button @click.prevent="onSubmit" type='submit' class="btn btn-primary">Submit</button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-secondary"
            ref="closeBtn"
            
            >
            Close
            </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>

import {Form, Field, useForm, useFieldArray } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import ChatGptService from '@/services/ChatGptService';
import {defineProps,defineEmits ,watch,ref,onMounted,reactive,inject} from 'vue';
import { activeLoader } from '@/utils/helpers';
import Errors from '@/utils/Errors';
import QuestionServices from '@/services/QuestionServices';
import * as Yup from 'yup';
const props = defineProps({
    topicContent: String,
    TopicId: Number,
    enableGptForm:Boolean,
});
const emit = defineEmits(['alertEvent'])
    const TopicQuestion = ref([]);
    const typeOptions = ref([]);
    const gptForm = ref(null);
    const notificationService = inject('$notification');
    const errors_v = reactive(new Errors());
    onMounted(async() => {
       await QuestionServices.getQuesitonType().then((response) => {
            typeOptions.value=response.data;
        }).catch(e=>{
            alert(e);
        })
    });
    const enableQuestionForm=ref(false);


    const gptQuestionAns = ref([]);
    const validateQuestionAnswers = (questionAnswers) => {
      gptQuestionAns.value = questionAnswers;
      const hasValidQuestion = questionAnswers.every(
        (item) => item.question && item.question.trim() !== ''
      );
      if (
        !hasValidQuestion 
      ) {
        return false;
      }

      const hasValidAnswers = questionAnswers.every((item) => {
        return (
          Array.isArray(item.answers) &&
          item.answers.length > 0 &&
          item.answers.every((answer) => {
            return (
              answer.option &&
              answer.option.trim() !== '' &&
              typeof answer.is_correct === 'number' &&
              (answer.is_correct === 0 || answer.is_correct === 1)
            );
          }) &&
          item.answers.some((answer) => answer.is_correct === 1)
        );
      });

      return hasValidAnswers;
    };
    const createQuestionAnswerStructure = (text) => {
      const lines = text.split('\n').filter((line) => line.trim() !== '');
      const questions = [];
      let currentQuestion;
      for (const line of lines) {
        if (line.startsWith('Question ')) {
          if (currentQuestion) {
            questions.push(currentQuestion);
          }
          currentQuestion = {
            question: line.slice(line.indexOf(':') + 2),
            answers: [],
          };
        } else if (line.startsWith('Answer ')) {
          const answerText = line.slice(line.indexOf(':') + 2);
          const isCorrect = answerText.includes('(correct)');
          currentQuestion.answers.push({
            option: isCorrect
              ? answerText.replace('(correct)', '').trim()
              : answerText.trim(),
            is_correct: isCorrect ? 1 : 0,
          });
        }
      }

      if (currentQuestion) {
        questions.push(currentQuestion);
      }
      if (questions && questions.length > 0) {
        return validateQuestionAnswers(questions);
      }
      return false;
    };
    const initialState = {
        totalQuestions:'',
        query: `Mutiple choise Question from paragraph ignore html tags with the must follow recomanded format that given below and add (correct) with right answer  and always give three Answers 
            demo format that you have to make like:
            Question 1: When beginning a real estate brokerage, what is important to determine in regards to business structure? Which forms of business can be established?
                Answer 1: A Limited Liability Company.
                Answer 2: A for-profit corporation.
                Answer 3: All of the above options . (correct)`,
        questionType:''
    };
    const form = initialState;
    const schema = Yup.object().shape({
        question_type: Yup.string().required('Type is required'),
        totalQuestions: Yup.number().required('Total Questions is required').min(1, 'Minimum 1 totalQuestions are required').max(5, 'Maximum 5 answers are allowed'),
        query: Yup.string().required('Query is required'),
    });

    const { handleSubmit,errors} = useForm({
    initialValues: {
        TopicQuestion: TopicQuestion.value,
    },
    validationSchema : Yup.object().shape({
        TopicQuestion: Yup.array().of(
            Yup.object().shape({
                question: Yup.string().required('Question is required'),
                is_active: Yup.number().required('is_active is required'),
                feedback_error: Yup.string().required('Incorrect response message is required'),
                feedback_correct: Yup.string().required('Correct response message is required'),
                use_for_exam: Yup.number().required('is_course_exam_question is required'),
                use_for_quiz: Yup.number().required('is_topic_quiz_question is required'),
                use_for_lesson: Yup.number().required('is_lesson_exam_question is required'),
                use_for_static_01: Yup.number().required('is_static_question_1 is required'),
                use_for_static_02: Yup.number().required('is_static_question_2 is required'),
                question_type: Yup.string().required('Question type is required'),
                Answers: Yup.array().when('question_type', {
                    is: "1",
                    then: Yup.array()
                    .of(
                    Yup.object().shape({
                       
                        answer: Yup.string().required('Answer is required'),
                        points: Yup.number().required('Points is required'),
                        correct: Yup.boolean().required('Correct is required'),
                    })
                    ).min(3, 'Minimum 3 answers are required').max(3, 'Maximum 3 answers are allowed')
                    .test('has-multiple-correct-answers', 'Choose one correct answers', (answers) => {
                        const correctAnswers = answers.filter((answer) => answer.correct);
                        return correctAnswers.length === 1;
                    }),
                    otherwise: Yup.array().when('question_type', {
                        is: "3",
                        then: Yup.array().of(
                            Yup.object().shape({
                                answer: Yup.string().required('Answer is required'),
                                points: Yup.number().required('Points is required'),
                                correct: Yup.boolean().required('Correct is required'),
                            })
                        ).min(1, 'Minimum 1 answers are required').max(1, 'Maximum 1 answers are allowed').test('has-multiple-correct-answers', 'Choose one correct answers', (answers) => {
                        const correctAnswers = answers.filter((answer) => answer.correct);
                            return correctAnswers.length === 1;
                        }),
                    }).when('question_type', {
                        is: "2",
                        then: Yup.array().of(
                        Yup.object().shape({
                            answer: Yup.string().required('Answer is required'),
                            points: Yup.number().required('Points is required'),
                            correct: Yup.boolean().required('Correct is required'),
                        })
                        )
                        .min(2, 'Minimum 2 answers are required')
                        .max(2, 'Maximum 2 answers are allowed').test('has-multiple-correct-answers', 'Choose one correct answers', (answers) => {
                        const correctAnswers = answers.filter((answer) => answer.correct);
                            return correctAnswers.length === 1;
                        }),
                    }),
                }),
            })
        ),
    })
});

const { remove, push, fields } = useFieldArray('TopicQuestion');
    const getGPTQuestion = async (values)=>{
        activeLoader('active', 'getGptQuestions');
        if(values.question_type==2){
            values.query=   `TRUE / FALSE Question and Answers from paragraph ignore html tags with the must follow recomanded format that given below and add (correct) with right answer  and always give two Answers
            demo format that you have to make like:
            Question 1: The earth is the fourth planet from the sun.
                Answer 1: FALSE.
                Answer 2: TRUE. (correct)`;
             
        }
        else if(values.question_type==3){
            values.query=`fill in the blank from paragraph ignore html tags with the must follow recomanded format that given below and add (correct) with right answer and always give one right Answers 
            demo format that you have to make like:
            Question 1: One in training to be a police or military officer __________.
            Answer 1: cadet. (correct)`
        }

        await ChatGptService.chatGptCompletion(`Create total ${values.totalQuestions}`+" "+values.query+" "+"paragraph:"+props.topicContent)
          .then((response) => {
            if (
              response.choices &&
              response.choices.length > 0 &&
              response.choices[0]['message']['content']
            ) {
                if (createQuestionAnswerStructure(response.choices[0]['message']['content'])) {
                   const newRecords  = gptQuestionAns.value.map((item) => {
                        return {
                            question: item.question,
                            is_active: 1,
                            feedback_error: '',
                            feedback_correct: '',
                            use_for_exam: 0,
                            use_for_quiz: 0,
                            use_for_lesson: 0,
                            use_for_static_01: 0,
                            use_for_static_02: 0,
                            question_type: values.question_type,
                            topic_id:props.TopicId,
                            Answers: item.answers.map((answer) => {
                                if(values.question_type == 1 || values.question_type==2 || values.question_type==3){
                                    return {
                                        answer: answer.option,
                                        correct: answer.is_correct,
                                        points:answer.is_correct === 1 ? 100 : 0,
                                    };
                                }
                            }),
                        };
                    });
                    TopicQuestion.value.push(...newRecords);
                    fields.value.push(...newRecords);
                    notificationService.success('Record Fetched Successfully!');
                    enableQuestionForm.value=true;
                   
                }else{
                    emit('alertEvent', 'wentWorng');
                }
            }
            
          })
          .catch((error) => {
            console.log(error);
            activeLoader('dactive', 'getGptQuestions');
            emit('alertEvent', 'wentWorng');
          });
          activeLoader('dactive', 'getGptQuestions');
    }


const pushAnswer=(topicQuestionIndex)=>{
    if (TopicQuestion.value[topicQuestionIndex].question_type === 2  ) {
        
        TopicQuestion.value[topicQuestionIndex].Answers.push({ answer: '', points: 0, correct: 0 });

    }else if (TopicQuestion.value[topicQuestionIndex].question_type === 1) {

        TopicQuestion.value[topicQuestionIndex].Answers.push({ answer: '', points: 0, correct: 0 });

    }else if (TopicQuestion.value[topicQuestionIndex].question_type === 3) {

        TopicQuestion.value[topicQuestionIndex].Answers.push({ answer: '', points: 0 ,correct: 1});
    }

}
const handleQuestionTypeChange = (idx) => {
  if (TopicQuestion.value[idx].question_type === 2) {
    TopicQuestion.value[idx].Answers = [{ answer:"", points: 0, correct: 0 }];
  } else if (TopicQuestion.value[idx].question_type === 1) {
    TopicQuestion.value[idx].Answers = [{ answer: "", points: 0, correct: 0 }];
  }else if (TopicQuestion.value[idx].question_type === 3) {
    TopicQuestion.value[idx].value.Answers = [{ answer: "", points: 0,correct: 1}];
  }
};

const removeAnswer =(index,answerIndex)=>{    
    TopicQuestion.value[index].Answers.splice(answerIndex, 1);
    if(TopicQuestion.value[index].Answers && TopicQuestion.value[index].Answers.length == 0)
    handleQuestionTypeChange(index);
     

}

watch(
    () => props.TopicId,
    async (currentValue, oldValue) => {
        if (currentValue != oldValue && currentValue != 0) {
            console.log(props.TopicId,'-------------',props.lessonId)
        }else{
            enableQuestionForm.value=false; 
        }
    }
);


const onSubmit = handleSubmit(async (values, { resetForm }) => {
    activeLoader('active', 'saveQuestions');
    await QuestionServices.addQuesitons(values,props.TopicId).then((response) => {
        resetForm();
        gptForm.value.resetForm();
        emit('reset','all');
        activeLoader('dactive', 'saveQuestions');
    }).catch(e=>{
        alert(e);
        activeLoader('dactive', 'saveQuestions');
    })
});

</script>

  