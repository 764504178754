<template>
<div
      class="modal fade p-0"
      id="previewModel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="d-flex flex-wrap w-100 position-relative event-auto">
          <div class="card shadow-none">
            <div
              class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
            >
              <div class="content-card-left w-100">
                <h4 class="card-title text-white">Preview Modal</h4>
                <p class="card-category mb-0">Topic Content Modal</p>
              </div>
            </div>
            <div class="card-body">
              <p v-html="content" class="previewContent"></p>
              <div class="col-12 text-end modal-footer mt-md-0 mt-4">
                <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-secondary"
                    @click="returnPreviousModel"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>
<script>
    export default {
    props: {
        content: String,
        previousModelId:String,
    },
    setup(props,{emit}) {
        const returnPreviousModel=()=>{
          emit('returnToPrevModel', props.previousModelId);
        }
        return {
          returnPreviousModel
        }
    }
}
</script>