import { useToast,} from 'vue-toastification';

const toast = useToast();
export function useNotificationService() {
  function success(message: string) {
    toast.success(message);
  }

  function error(message: string) {
    toast.error(message);
  }

  function info(message: string) {
    toast.info(message);
  }

  function warning(message: string) {
    toast.warning(message);
  }

  return {
    success,
    error,
    info,
    warning,
  };
}

export default {
    install: (app) => {
      const notificationService = useNotificationService();
      app.provide('$notification', notificationService);
    },
};
  


