<template>
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">Users</h4>
                  <p class="card-category mb-0">
                    Here you can view user's data
                  </p>
                </div>
                <div class="content-card-right ms-auto">
                  <a
                    href="#addEditUser"
                    data-bs-toggle="modal"
                    data-bs-target="#addEditUser"
                    @click.prevent
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle"
                  >
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <div class="header-content">
                  <form
                    class="table-header mx-0 mb-1 custom-form position-sticky"
                    @submit="onSubmit"
                  >
                  <div class="row mb-4 pb-lg-2">
                      <div class="col-sm-6 col-lg-3 ms-auto">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="filterByterm"
                            v-model="values.filterByterm"
                            :class="[
                              values.filterByterm == '' ? '' : 'active',
                              'border-0 position-relative w-100',
                            ]"
                            @input="changeTitleSearch()"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >Filter By Term</label
                          >
                   
                        </div>
                      </div>
                    </div>
                    <div class="row gy-4 gy-lg-0 mb-4">
                      <div class="col-sm-6 col-lg-3">
                        <div class="position-relative">
                          <label
                            for=""
                            :class="[
                              `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                                (values.filterBySchool || multiselectFocus[0])
                                  ? 'active'
                                  : ''
                              }`,
                            ]"
                            >Search by school</label
                          >
                          <Field
                            name="filterBySchool"
                            v-model="values.filterBySchool"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              v-bind="field"
                              v-model="values.filterBySchool"
                              :searchable="true"
                              :close-on-select="false"
                              :options="pageData.allSchools"
                              @open="multiselectFocus[0] = true"
                              @close="multiselectFocus[0] = false"
                              @Change="getUsers"
                              class="custom-multiselect single-select-searchable border-0 position-relative w-100"
                            />
                          </Field>
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-3">
                        <div class="position-relative">
                          <label
                            for=""
                            :class="[
                              `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                                (values.filterByCourse || multiselectFocus[2])
                                
                                  ? 'active'
                                  : ''
                              }`,
                            ]"
                            >Search by course</label
                          >
                          <Field
                            name="filterByCourse"
                            v-model="values.filterByCourse"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              v-bind="field"
                              v-model="values.filterByCourse"
                              :searchable="true"
                              :close-on-select="false"
                              :options="pageData.allCourses"
                              @open="multiselectFocus[1] = true"
                              @close="multiselectFocus[1] = false"
                              @Change="getUsers"
                              class="custom-multiselect single-select-searchable border-0 position-relative w-100"
                            />
                          </Field>
                       
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-3">
                        <div class="position-relative">
                          <label
                            for=""
                            :class="[
                              `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                                (values.filterByUserType || multiselectFocus[1])
                                  ? 'active'
                                  : ''
                              }`,
                            ]"
                            >Search user by type</label
                          >
                          <Field
                            name="filterByUserType"
                            v-model="values.filterByUserType"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              v-bind="field"
                              v-model="values.filterByUserType"
                              :searchable="true"
                              :close-on-select="false"
                              :options="pageData.allUserTypes"
                              @open="multiselectFocus[2] = true"
                              @close="multiselectFocus[2] = false"
                              @Change="getUsers"
                              class="custom-multiselect single-select-searchable border-0 position-relative w-100"
                            />
                          </Field>
                        
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-3">
                        <div class="position-relative">
                          <label
                            for=""
                            :class="[
                              `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                                (values.previousSearchFilters  ||
                                multiselectFocus[3])
                                  ? 'active'
                                  : ''
                              }`,
                            ]"
                            >Previous Search</label
                          >
                          <Field
                            name="previousSearchFilters"
                            v-model="values.previousSearchFilters"
                            v-slot="{ field }"
                          >
                            <Multiselect
                              v-bind="field"
                              v-model="values.previousSearchFilters"
                              :searchable="true"
                              :close-on-select="false"
                              :options="pageData.allUserFilters"
                              @open="multiselectFocus[3] = true"
                              @close="multiselectFocus[3] = false"
                              @Change="setSearchFilter(values.previousSearchFilters)"
                              class="custom-multiselect single-select-searchable border-0 position-relative w-100"
                            />
                          </Field>
                        </div>
                      </div>
                      <div class="col-12 mt-4 pt-2">
                        <div class="row gy-4 gy-sm-0">
                          <div class="col-sm-6 col-lg-3 ms-lg-auto">
                            <div class="position-relative ms-auto">
                              <Field
                                type="text"
                                name="filterName"
                                v-model="values.filterName"
                                :class="[
                                  values.filterName == '' ? '' : 'active',
                                  'border-0 position-relative w-100',
                                ]"
                              />
                              <label for="" class="mb-0 placeholder-label"
                                >Filter Name</label
                              >
                              <p
                                v-if="errors.filterName"
                                class="invalid-message mb-0 pt-2"
                              >
                                {{ errors.filterName }}
                              </p>
                            </div>
                          </div>
                          <div class="col-sm-6 col-lg-auto">
                            <button
                              type="submit"
                              class="btn btn-secondary w-100 font-14"
                             
                            >
                            <div
                              id="saveFilter"
                              class="d-none spinner-border me-2"
                              role="status"
                          ></div>
                              Save Search
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-if="values.filterByCourse || values.filterByterm || values.filterBySchool || values.filterByUserType" class="col-12 d-flex mt-4 pt-2">
                        <button
                          type="button"
                          class="btn btn-danger font-14 d-flex gap-1 align-items-center ms-auto"
                          @click.prevent="resetForm(),getUsers()"
                          >
                          <img
                            src="@/assets/images/clear-filter.png"
                            class="size-sm contain"
                            alt="filter-icon"
                          />
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </form>

                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table table-lg">
                      <thead>
                        <tr>
                          <th class="text-capitalize">Id</th>
                          <th class="text-capitalize">Username</th>
                          <th class="text-capitalize">First Name</th>
                          <th class="text-capitalize">Last Name</th>
                          <th class="text-capitalize">Phone</th>
                          <th class="text-capitalize">Email</th>
                          <th class="text-capitalize">schools</th>
                          <th class="text-capitalize text-center">Status</th>
                          <th class="text-capitalize text-center">User Type</th>
                          <th class="text-capitalize text-center">Transactions</th>
                          <th class="text-capitalize text-center">Track Exams</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                        <dataLoader  v-if="
                            !pageData.allUsers && pageData.allUsers == ''
                          " :loaderClasses="'custom-animated-loader d-inline-block position-relative'" />
                        <tr
                          v-for="user in pageData.allUsers.data"
                          :key="user.id"
                        >
                          <td>{{ user.id }}</td>
                          <td>{{ user.username }}</td>
                          <td>{{ user.first_name??'---' }}</td>
                          <td>{{ user.last_name??'---' }}</td>
                          <td>{{ user.phone??'---' }}</td>
                          <td>{{ user.email??'---' }}</td>
                          <td v-if="user.school && user.school.length>0">
                            <span class="d-block mb-2"
                              v-for="(school,index) in user.school"
                              :key="school.id+'_'+index"
                            >
                              {{ school.name ? school.name : '' }}</span
                            >
                          </td>
                          <td v-else>
                           {{ '---' }}
                          </td>
                          <td>
                      
                            <span
                              :class="[user.is_active?'text-success active':'text-light-gray inactive', 'status  d-flex align-items-center rounded-pill fw-semibold ps-2']"
                              >
                                <span
                                :class="[user.is_active?'bg-success':'bg-light-gray', 'dot flex-shrink-0 rounded-circle me-2']"
                                ></span>
                                {{user.is_active?'Active':'Inactive'}}</span
                              >
                          </td>
                          <td v-if="user.roles && user.roles.length>0">
                            <span 
                              v-for="(role,index) in user.roles"
                              :key="role.id"
                              :style="{ 'background-color': colorType[index] }"
                                :class="[
                                  `user-type font-13 text-white text-center 
                                rounded-1 d-block p-1 ${
                                  index != colorType.length - 1
                                    ? 'mb-2'
                                    : ''
                                }`,
                                ]"
                            >
                              {{ role.name ? role.name : '' }}</span
                            >
                          </td>
                          <td v-else>
                           {{ '---' }}
                          </td>
                            <td>
                               <router-link :to="`/transactions/${user.id}`">
                                <button class="btn btn-danger font-14">
                                  Transactions
                                </button>
                              </router-link>
                            </td>
                            <td>  <router-link to="/">
                                <button class="btn btn-success font-14">
                                  
                                  Track Exams
                                </button>
                              </router-link>
                            </td>
                        
                        </tr>
                        <noRecordeFound v-if="pageData.allUsers && pageData.allUsers.data && pageData.allUsers.data.length==0" />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              >
              <!-- <p>{{ pageData.allUsers.data ?? '' }}</p> -->
                <p
                  v-if="
                    pageData.allUsers &&
                    pageData.allUsers.data 
                  "
                  class="d-lg-inline-block table-data"
                >
                  Showing {{ pageData.allUsers.from ?? '' }} to
                  {{ pageData.allUsers.to ?? '' }} of
                  {{ pageData.allUsers.total ?? '' }} entries..
                </p>
                <div
                  v-if="
                    pageData.allUsers &&
                    pageData.allUsers.data
                  "
                  class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                >
                  <v-pagination
                    class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                    v-model="values.page"
                    :pages="
                      pageData.allUsers ? pageData.allUsers.last_page : null
                    "
                    :range-size="1"
                    @update:modelValue="getUsers"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
<AddEditUserModel/>

 
</template>

<script>
import '@/assets/scss/pages/shop.scss';
import UserService from '@/services/UserService';
import SchoolService from '@/services/SchoolService';
import courseServices from '@/services/courseServices';
import FilterService from '@/services/FilterService';
import { onMounted, ref, reactive,inject } from 'vue';

import AddEditUserModel from '@/components/modals/AddEditUserModel';
import dataLoader from '@/components/dataLoader';
import VPagination from '@hennge/vue3-pagination';
import '@vueform/multiselect/themes/default.css';
import debounce from 'lodash/debounce';
import noRecordeFound from '@/components/noRecordeFound';
import { Field,useForm } from 'vee-validate';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect';
import { activeLoader } from '@/utils/helpers';
export default {
  name: 'users',
  components: {
    VPagination,
    dataLoader,
    noRecordeFound,
    Field,
    Multiselect,
    AddEditUserModel
  },
  setup() {
    const paginationPage = ref(1);
    const schema = Yup.object().shape({
      filterName: Yup.string()
        .required('name is required').nullable(),
    });
    const multiselectFocus = ref([false, false, false]);
    const notificationService = inject('$notification');
    const { handleSubmit,values, errors,resetForm} = useForm({
      initialValues: {
        filterName:'',
        previousSearchFilters:'',
        filterByterm:'',
        filterBySchool:'',
        filterByCourse:'',
        filterByUserType:'',
        page:1,
      },
      validationSchema: schema,
    });
    const pageData = reactive({
      allUsers: '',
      allSchools:'',
      allCourses:'',
      allUserTypes:[
        {
          value:'no_purchase',label:'Students with no purchase'
        },
        {
          value:'manual',label:'Manually added Students'
        },
        {
          value:'both',label:'Manually added Students with no purchase'
        }
      ],
      allUserFilters:''
    });
   
    const colorType = [
      '#7B0000', 
      '#0F8D00', 
      '#46458C', 
      '#F7B400',
      '#000'   , 
    ];
    const courseId=ref(0);
 
    const getUsers = async (val = null) => {
      pageData.allUsers = '';
      const params={
        by_term   :values.filterByterm,        
        by_school :values.filterBySchool,     
        by_course :values.filterByCourse ,    
        by_type   :values.filterByUserType,   
        page      :values.page
      }
      await UserService
        .getUsers(params)
        .then(async (response) => {
          pageData.allUsers = response.data;
        })
        .catch((e) => {
          alert(e);
        });
    };
  
    const setSearchFilter =async (id)=>{
      if(id && pageData.allUserFilters && pageData.allUserFilters.length>0){
        const filter= await pageData.allUserFilters.filter(item => item.value == id);
          values.filterByterm       =filter[0]['by_term']?filter[0]['by_term']:'',
          values.filterBySchool     =filter[0]['by_school']?filter[0]['by_school']:'',
          values.filterByCourse     =filter[0]['by_course']?filter[0]['by_course']:'',
          values.filterByUserType   =filter[0]['by_type']!=-1?filter[0]['by_type']:'',
          values.page=1
      }
      getUsers();
    }
    const getSchools=async ()=>{
      await SchoolService
        .getSchools()
        .then(async (response) => {
          pageData.allSchools = response.data.data;
        })
        .catch((e) => {
          alert(e);
        });
    }
    const getCourses=async ()=>{
      await courseServices
        .getCourses()
        .then(async (response) => {
          pageData.allCourses = response.data.data;
        })
        .catch((e) => {
          alert(e);
        });
    }
    const getUserFilters=async ()=>{
      await FilterService
        .getUserFilters()
        .then(async (response) => {
          pageData.allUserFilters = response.data.data;
        })
        .catch((e) => {
          alert(e);
        });
    }
    onMounted(() => {
      getUsers();
      getSchools();
      getCourses();
      getUserFilters();
    });
    const changeTitleSearch = debounce(async () => {
      getUsers();
    }, 1000)

    const onSubmit = handleSubmit(async values => {
      if(values.filterByCourse || values.filterByterm || values.filterBySchool || values.filterByUserType){
        activeLoader('active','saveFilter');
       const payload={
        by_term   :values.filterByterm?values.filterByterm:'',
        by_school :values.filterBySchool?values.filterBySchool:0,
        by_type   :values.filterByUserType?values.filterByUserType:-1,
        by_course :values.filterByCourse?values.filterByCourse:0,
        type:1,
        name:values.filterName
      }
      await FilterService
        .createFilter(payload)
        .then(async () => {
           notificationService.success('Rcorde SuceessFully Created!');
          getUserFilters();
        })
        .catch((e) => {
          alert(e);
        });
        activeLoader('dactive','saveFilter');
      }
    });
    return {
      getUsers,
      paginationPage,
      pageData,
      changeTitleSearch,
      courseId,
      colorType,
      multiselectFocus,
      onSubmit,
      values, 
      errors,
      setSearchFilter,
      resetForm,
    };
  },
};
</script>
