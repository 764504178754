export const getError = (error) => {

    const errorMessage = "API Error, please try again.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (!error.response) {
        console.error(`API ${error.config.url} not found`);
        return errorMessage;
    }
    if (process.env.NODE_ENV === "development") {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
    }
    if (error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }

    return errorMessage;
};

export const activeLoader = (type: string, id: string): void => {
    if (type === 'active' && id) {
      const loader = document.getElementById(id) as HTMLElement;
      const parentElement = loader?.parentElement as HTMLInputElement;
      if (parentElement) {
        parentElement.disabled = true;
      }
      loader?.classList.remove('d-none');
    } else if (type === 'dactive' && id) {
      const loader = document.getElementById(id) as HTMLElement;
      loader?.classList.add('d-none');
      const parentElement = loader?.parentElement as HTMLInputElement;
      if (parentElement) {
        parentElement.disabled = false;
      }
    }
};
export const addBaseUrlToImages = (content) => {
  let updatedContent=content;
  if(updatedContent){
    const baseUrl = process.env.VUE_APP_IMG_BASE_URL; // Replace with your base URL
    const imageTagRegex = /<img[^>]+src="([^">]+)"/g;
    updatedContent = content.replace(
      imageTagRegex,
      `<img src="${baseUrl}$1"`
    );
   }
  return updatedContent;
};

export const removeBaseUrlFromImages = (content) => {
  const baseUrl = process.env.VUE_APP_IMG_BASE_URL; // Replace with your base URL
  const imageTagRegex = new RegExp(`<img src="${baseUrl}([^">]+)"`, "g");
  const updatedContent = content.replace(imageTagRegex, `<img src="$1"`);
  return updatedContent;
};

export const UnixTimeStamp = (value) => {
  const date = new Date(value);
  const timestamp = Math.floor(date.getTime() / 1000);
  return timestamp;

};