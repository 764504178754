import * as API from "@/services/API";
export default {
    async login(payload) {
        await API.apiClient.get("/sanctum/csrf-cookie");
        const response = await API.apiClient.post("/login", payload);
        return response;
    },
    async register(payload) {
        await API.apiClient.get("/sanctum/csrf-cookie");
        return API.apiClient.post("/api/stores", payload);
    },
    async logout() { 
        localStorage.removeItem('referrer');
        localStorage.removeItem('kohana_token');
        await API.apiClient.post("/logout");
        
    },
    async getAuthUser() {
        const {data} = await API.apiClient.get("/api/users/create");
        return data;
    }
};
