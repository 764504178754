<template>
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">Users Transactions</h4>
                  <p class="card-category mb-0">
                    Here you can view Transaction's data
                  </p>
                </div>
                <!--  -->
              </div>
              <div class="card-body">
                <div class="header-content">
        

                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table table-lg">
                      <thead>
                        <tr>
                          <th class="text-capitalize text-center">Status</th>
                          <th class="text-capitalize">Products</th>
                          <th class="text-capitalize">Transaction amount</th>
                          <th class="text-capitalize">Transaction date</th>
                          <th class="text-capitalize">Actions</th>
                    
                        </tr>
                      </thead>
                      <tbody>
                       
                        <dataLoader  v-if="
                            !pageData.userTransactions && pageData.userTransactions == ''
                          " :loaderClasses="'custom-animated-loader d-inline-block position-relative'" />
                        <tr
                          v-for="transaction in pageData.userTransactions.data"
                          :key="transaction.id"
                        >
                          <!-- <td>{{ transaction.status_name }}</td> -->
                          <span
                              :class="[transaction.status_name?'text-success active':'text-light-gray inactive', 'status  d-flex align-items-center rounded-pill fw-semibold ps-2']"
                              >
                                <span
                                :class="[transaction.status_name?'bg-success':'bg-light-gray', 'dot flex-shrink-0 rounded-circle me-2']"
                                ></span>
                                {{transaction.status_name?transaction.status_name:''}}</span
                              >
                          <td>{{ transaction.product_name }}</td>
                          <td>{{ transaction.amount?'$'+transaction.amount:'---' }}</td>
                          <td>{{ transaction.date??'---' }}</td>
                          <td>
                               <router-link :to="`/invoice/${transaction.id}`">
                                <button class="btn btn-success font-14">
                                  view invoice
                                </button>
                              </router-link>
                            </td>

                        </tr>
                        <noRecordeFound v-if="pageData.userTransactions && pageData.userTransactions.data && pageData.userTransactions.data.length==0" />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              >
              <!-- <p>{{ pageData.userTransactions.data ?? '' }}</p> -->
                <p
                  v-if="
                    pageData.userTransactions &&
                    pageData.userTransactions.data 
                  "
                  class="d-lg-inline-block table-data"
                >
                  Showing {{ pageData.userTransactions.from ?? '' }} to
                  {{ pageData.userTransactions.to ?? '' }} of
                  {{ pageData.userTransactions.total ?? '' }} entries..
                </p>
                <div
                  v-if="
                    pageData.userTransactions &&
                    pageData.userTransactions.data
                  "
                  class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                >
                  <v-pagination
                    class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                    v-model="page"
                    :pages="
                      pageData.userTransactions ? pageData.userTransactions.last_page : null
                    "
                    :range-size="1"
                    @update:modelValue="getUserTransactions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
 
</template>

<script>
import { onMounted,reactive,ref } from 'vue';
import UserService from '@/services/UserService';
import { useRoute } from 'vue-router';
import dataLoader from '@/components/dataLoader';
import VPagination from '@hennge/vue3-pagination';
import noRecordeFound from '@/components/noRecordeFound';
export default {
  name: 'usersTransactions',
  components: {
    VPagination,
    dataLoader,
    noRecordeFound,
  },
  setup() {
    const page =ref(1);
    const route = useRoute();
    const pageData = reactive({
      userTransactions: '',
     
    });
   

 
    const getUserTransactions = async (val = null) => {
      pageData.userTransactions = '';
      const id = Number(route.params.id);
      console.log(id,'---------------')
      await UserService
        .getUserTransactions(val ? val : 1,id)
        .then(async (response) => {
          console.log(response);
          pageData.userTransactions = response.data;
        })
        .catch((e) => {
          alert(e);
        });
    };
  

    
    onMounted(() => {
      getUserTransactions();

    });
 
  
    return {
      getUserTransactions,
      pageData,
      page
  
    };
  },
};
</script>
