
<template>

      <aside class="sidebar position-fixed">
        <div class="logo position-relative">
          <p class="text-center mb-0 text-uppercase">LMS</p>
        </div>
      
        <div class="sidelinks-wrapper">
          <ul class="list-unstyled p-0" >
            <li :class="[`${subMenuOpen[0] ? 'show' : ''}`]"> 
              <router-link
            @click="toggleSubMenu(0)"
            to="/courses"
            :class="[`d-flex flex-wrap align-items-center`]"
          >
            <img src="@/assets/images/lessons.png" class="contain me-3" />
            <p class="flex-grow-1 mb-0 pe-1">Courses</p>
            <img
              src="@/assets/images/down-arrow.png"
              :class="[
                `${
                  subMenuOpen[0] ? 'rotate-up' : ''
                } contain transition flex-shrink-0 ms-auto`,
              ]"
            />
          </router-link>
              <ul class="side-collapse submenu w-100 p-0">
                  <li>
                    <router-link to="/packages" class="d-flex flex-wrap align-items-center">
                      <i class="character position-relative text-uppercase fst-normal fw-normal text-center"><i class="bi bi-box-seam"></i></i>
                      <p class="mb-0">packages</p>
                    </router-link>
                  </li>
                </ul>
            </li>
            <li :class="[`${subMenuOpen[1] ? 'show' : ''}`]"> 
              <router-link
                  @click="toggleSubMenu(1)"
                  to="/users"
                  :class="[`d-flex flex-wrap align-items-center`]"
                >
                  <img src="@/assets/images/lessons.png" class="contain me-3" />
                  <p class="flex-grow-1 mb-0 pe-1">Users</p>
                  <!-- <img
                    src="@/assets/images/down-arrow.png"
                    :class="[
                      `${
                        subMenuOpen[1] ? 'rotate-up' : ''
                      } contain transition flex-shrink-0 ms-auto`,
                    ]"
                  /> -->
                </router-link>
            </li>
          </ul>
          
        </div>

      </aside>
</template>
<script>
import '@/assets/scss/layout/_sidebar.scss';
import {computed, watch, ref,isRef} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default{
  name: 'Sidebar',
  setup(){
    const dropdown=ref(false);
    const store=useStore();
    const user_active=ref('');
    const router= useRouter();
    const subMenuOpen = ref([false]);
    

    const toggleSubMenu = (index) => {
      subMenuOpen.value.filter((item, i) => {
        if (i !== index) subMenuOpen.value[i] = false;
      });
      subMenuOpen.value[index] = !subMenuOpen.value[index];
    }
    watch(async () => {
      try {
        const user = computed(() => store.state.Auth.user);
        if (isRef(user) && user.value) {
          user_active.value=user.value.is_active;
          if(user_active.value ==0){
            await router.push('/');
          }
        } 
      } catch (e) {
        await router.push('/');
      }
    });
      
    function arrowDown(event) {
      const active = document.querySelectorAll('.show');
        active.forEach(node => {
          if(node != event.currentTarget.parentNode) {
            node.classList.remove("show");
          }
        });
        event.currentTarget.parentNode.classList.toggle("show");
        
      }

    return {
      dropdown,
      arrowDown,
      user_active,
      subMenuOpen,
      toggleSubMenu,
    }
  }
}
</script>
