<template>
   <tr>
    <td colspan="100%" class="text-center">
    <img
        src="@/assets/images/no-record.png"
        class="size-100 contain"
        alt="no-record"
    />
    <h5 class="mt-2 mb-0">No Record Found</h5>
    </td>
</tr>
</template>
<script>
    export default {
    setup(props) {
        return {
        }
    }
}
</script>