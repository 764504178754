import * as API from "@/services/API";

export default {
    async getUsers(params){
        return await API.apiClient.get('/api/users', { params });
    }, 
    async createUser(payload){
        return await API.apiClient.post('/api/users',payload);
    }, 

    async getUserTransactions(page,id){
        return await API.apiClient.get(`/api/users/transaction/${id}?page=${page}`);
    }
};