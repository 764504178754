import * as API from "@/services/API";

export default {
    async getUserFilters(){
        return await API.apiClient.get('/api/filters');
    },
    
    async createFilter(payload){
        return await API.apiClient.post('/api/filters',payload);
    }, 
};