<template>
    <div class="modal fade p-0" id="addEditUser" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="d-flex flex-wrap w-100 position-relative event-auto">
              <div class="card shadow-none">
                <div
                  class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
                >
                  <div class="content-card-left w-100">
                    <h4 class="card-title text-white">User Information</h4>
                    <p class="card-category mb-0">{{'Add User Modal'}}</p>
                  </div>
                </div>
                <div class="card-body">
                    <form
                    class="custom-form mt-2"
                    @submit="onSubmit"
                >
                    <div class="row">
                      <!-- General Information  -->
                      <div class="col-6">
                        <h3 class="font-18 fw-semibold mb-0">
                          Personal Information 
                        </h3>
                      </div>
                      <div class="col-6">
                        <h3 class="font-18 fw-semibold mb-0">
                          Contact Information
                        </h3>
                      </div>
                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="firstName"
                            v-model="values.firstName"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.firstName
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >First name</label
                          >
                          <p v-if="errors.firstName" class="invalid-message mb-0 pt-2">
                            {{errors.firstName}}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="email"
                            name="email"
                            v-model="values.email"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.email
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >Email</label
                          >
                          <p v-if="errors.email" class="invalid-message mb-0 pt-2">
                            {{errors.email}}
                          </p>
                        </div>
                      </div>

                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="lastName"
                            v-model="values.lastName"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.lastName
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >last name</label
                          >
                          <p v-if="errors.lastName" class="invalid-message mb-0 pt-2">
                            {{errors.lastName}}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="phone"
                            v-model="values.phone"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.phone
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >phone</label
                          >
                          <p v-if="errors.phone" class="invalid-message mb-0 pt-2">
                            {{errors.phone}}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="userName"
                            v-model="values.userName"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.userName
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >User name</label
                          >
                          <p v-if="errors.userName" class="invalid-message mb-0 pt-2">
                            {{errors.userName}}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="company"
                            v-model="values.company"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.company
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >Company</label
                          >
                          <p v-if="errors.company" class="invalid-message mb-0 pt-2">
                            {{errors.company}}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 mt-4">
                        <Field name="dateOfbirth" v-slot="{ field }">
                            <v-date-picker
                            v-bind="field"
                            v-model="values.dateOfbirth"
                            :popover="{ visibility: 'focus' }"
                            :input-debounce="500"
                            class="custom-calendar position-relative single-calendar w-100 single-calendar"
                            mode="date"
                            
                            >
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                :class="[
                                    (!values.dateOfbirth) ? '' : 'active',
                                    'border-0 position-relative w-100',
                                ]"
                                :value="inputValue"
                                v-on="inputEvents"
                                />
                                <label for="" class="mb-0 placeholder-label"
                                >Date of Birth</label
                                >
                            </template>
                            </v-date-picker>
                        </Field>
                    </div>
                    <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="city"
                            v-model="values.city"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.city
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >City</label
                          >
                          <p v-if="errors.city" class="invalid-message mb-0 pt-2">
                            {{errors.city}}
                          </p>
                        </div>
                      </div>
                   
                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="zip"
                            v-model="values.zip"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.zip
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >ZIP</label
                          >
                          <p v-if="errors.zip" class="invalid-message mb-0 pt-2">
                            {{errors.zip}}
                          </p>
                        </div>
                      </div>

                      
                 

                
                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="address"
                            v-model="values.address"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.address
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >Address</label
                          >
                          <p v-if="errors.address" class="invalid-message mb-0 pt-2">
                            {{errors.address}}
                          </p>
                        </div>
                      </div>


                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="password"
                            name="password"
                            v-model="values.password"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.password
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >Password</label
                          >
                          <p v-if="errors.password" class="invalid-message mb-0 pt-2">
                            {{errors.password}}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                    <div class="position-relative mt-4">
                      <label
                        for=""
                        :class="[
                          `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                            values.state
                              ? 'active'
                              : ''
                          }`,
                        ]"
                        >Select State</label
                      >
                      <Field
                        name="state"
                        v-model="values.state"
                        v-slot="{ field }"
                      >
                        <Multiselect
                          v-bind="field"
                          v-model="values.state"
                          :searchable="true"
                          :close-on-select="false"
                          :options="modelData.stateOptions"
                          @open="multiselectFocus[2] = true"
                          @close="multiselectFocus[2] = false"
                          class="custom-multiselect border-0 position-relative w-100"
                        />
                      </Field>

                    </div>
                  </div>
                      <div class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="password"
                            name="cPassword"
                            v-model="values.cPassword"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.cPassword
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >Confirm Password</label
                          >
                          <p v-if="errors.cPassword" class="invalid-message mb-0 pt-2">
                            {{errors.cPassword}}
                          </p>
                        </div>
                      </div>
                     
                 
                    <div class="col-lg-6 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                      <Field
                        type="checkbox"
                        name="hasRealEstateLicense"
                        id="hasRealEstateLicense"
                        :value="1"
                        :unchecked-value="0"
                        v-model="values.hasRealEstateLicense"
                      />
                      <label for="hasRealEstateLicense" class="mb-0"
                        >I have Real Estate License #</label
                      >
                    </div>
                  </div>
                  <!--  -->
                  <div v-if="values.hasRealEstateLicense" class="col-lg-6 mt-4">
                        <Field name="licenseExpiration" v-slot="{ field }">
                            <v-date-picker
                            v-bind="field"
                            v-model="values.licenseExpiration"
                            :popover="{ visibility: 'focus' }"
                            :input-debounce="500"
                            class="custom-calendar position-relative single-calendar w-100 single-calendar"
                            mode="date"
                            
                            >
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                :class="[
                                    (!values.licenseExpiration) ? '' : 'active',
                                    'border-0 position-relative w-100',
                                ]"
                                :value="inputValue"
                                v-on="inputEvents"
                                />
                                <label for="" class="mb-0 placeholder-label"
                                >License Expiration</label
                                >
                            </template>
                            </v-date-picker>
                        </Field>
                        <p v-if="errors.licenseExpiration" class="invalid-message mb-0 pt-2">
                            {{errors.licenseExpiration}}
                          </p>
                    </div>
                  <div v-if="values.hasRealEstateLicense" class="col-6 mt-4">
                        <div class="position-relative">
                          <Field
                            type="text"
                            name="realEstateLicense"
                            v-model="values.realEstateLicense"
                            :class="{
                            'border-0': true,
                            'position-relative': true,
                            'w-100': true,
                            'active': values.realEstateLicense
                          }"
                          />
                          <label for="" class="mb-0 placeholder-label"
                            >Real Estate License #</label
                          >
                          <p v-if="errors.realEstateLicense" class="invalid-message mb-0 pt-2">
                            {{errors.realEstateLicense}}
                          </p>
                        </div>
                      </div>

                    <div class="col-lg-6 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                      <Field
                        type="checkbox"
                        name="haveDiploma"
                        id="haveDiploma"
                        :value="1"
                        :unchecked-value="0"
                        v-model="values.haveDiploma"
                      />
                      <label for="haveDiploma" class="mb-0"
                        >Do you have a High School Diploma, GED, or equivalent?</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                      <Field
                        type="checkbox"
                        name="isAffiliated"
                        id="isAffiliated"
                        :value="1"
                        :unchecked-value="0"
                        v-model="values.isAffiliated"
                      />
                      <label for="isAffiliated" class="mb-0"
                        >User is affiliate</label
                      >
                    </div>
                  </div>
                    <div class="col-6">
                        <div class="position-relative mt-4">
                        <label
                            for=""
                            :class="[
                            `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                                values.school
                                ? 'active'
                                : ''
                            }`,
                            ]"
                            >Select School</label
                        >
                        <Field
                            name="school"
                            v-model="values.school"
                            v-slot="{ field }"
                        >
                            <Multiselect
                            v-bind="field"
                            v-model="values.school"
                            :options="modelData.stateSchools"
                            @open="multiselectFocus[1] = true"
                            @close="multiselectFocus[1] = false"
                            class="custom-multiselect border-0 position-relative w-100"
                            />
                        </Field>
                        <p v-if="errors.school" class="invalid-message mb-0 pt-2">
                            {{errors.school}}
                          </p>
                        </div>
                  </div>

                  <div  class="col-lg-6 mt-4">
                    <div class="position-relative">
                      <label
                        for=""
                        :class="[
                          `placeholder-label multiselect-placeholder pe-none ps-0 mb-0 ${
                            (values.accountType && values.accountType.length>0 || multiselectFocus[0])
                              ? 'active'
                              : ''
                          }`,
                        ]"
                        >Account Types</label
                      >
                      <Field
                        name="accountType"
                        v-model="values.accountType"
                        v-slot="{ field }"
                      >
                        <Multiselect
                          v-bind="field"
                          v-model="values.accountType"
                          mode="tags"
                          :multiple="true"
                          :taggable="true"
                          :searchable="true"
                          :close-on-select="false"
                          :options="modelData.accountTypes"
                          @open="multiselectFocus[0] = true"
                          @close="multiselectFocus[0] = false"
                          class="custom-multiselect border-0 position-relative w-100"
                        />
                      </Field>

                      <p v-if="errors.accountType" class="invalid-message mb-0 pt-2">
                        {{errors.accountType}}
                      </p>
                    </div>
                  </div>
                  <div v-if="packageId && packageId!=0" class="col-12 mt-4">
                    <div class="d-flex gap-2 align-items-center">
                      <Field
                        type="file"
                        name="fileInput"
                        hidden
                        ref="singleFileInput"
                        id="fileInput"
                        v-model="values.fileInput"
                        @change="handleFileChange()"
                      />
                      <div class="uploaded-file-area position-relative w-100">
                        <i
                          v-if="values.fileInput"
                          @click="cancelUpload()"
                          class="bi bi-x-circle-fill position-absolute top-0 end-0 lh-1 fs-5 m-1"
                        ></i>
                        <label
                          for="fileInput"
                          class="upload-label upload-img bg-white d-flex justify-content-center align-items-center w-100 px-2 py-3 mb-0"
                        >
                          <!-- before-upload  -->
                          <div
                            v-if="!values.fileInput"
                            class="before-upload d-flex flex-column justify-content-center align-items-center"
                          >
                            <i class="bi bi-cloud-arrow-up-fill lh-1 fs-2"></i>
                            Upload file
                          </div>
                          <!-- after-upload  -->
                          <div v-else class="after-upload h-100 w-100">
                            <img
                              :src=" uploadedFileURL?uploadedFileURL:baseUrl+values.fileInput"
                              class="w-100 h-100 contain"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                      <div class="col-12 text-end modal-footer mt-4">
                        <button type="submit" class="btn btn-primary">
                          <div
                          id="add-user"
                          class="d-none spinner-border me-2"
                          role="status"
                        ></div>
                          Submit
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          class="btn btn-secondary"
                          ref="closeBtn"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    </template>
<script>
import '@/assets/scss/pages/shop.scss';
import '@vueform/multiselect/themes/default.css';
import {Field, useForm} from 'vee-validate';
import UserService from '@/services/UserService';
import StateService from '@/services/StateService';
import * as Yup from 'yup';
import { UnixTimeStamp,activeLoader } from '@/utils/helpers';
import { ref,onMounted,reactive,computed,inject } from 'vue';
import Multiselect from '@vueform/multiselect';
export default {
  name: 'addeditUser',
  components: {
    Field,
    Multiselect,
  },
  setup() {
    const closeBtn= ref();
    const notificationService = inject('$notification');
    const multiselectFocus = ref([false, false, false]);
    const modelData = reactive({
      stateOptions: '',
      stateSchools:[],
      accountTypes:''
    });
    const getStates=async()=>{
        await StateService
        .getStates()
        .then(async (response) => {
    
          modelData.stateOptions = response.data.data?response.data.data:[];
        })
        .catch((e) => {
          alert(e);
        });
        
    }
    const getAccountRole=async()=>{
        await StateService
        .getAccountRole()
        .then(async (response) => {
            
            modelData.accountTypes=response.data.data;
        })
        .catch((e) => {
          alert(e);
        });
        
    }
    
    const getStateSchools=async()=>{
        await StateService
        .getStateSchools()
        .then(async (response) => {
                response.data.map(state=>{
                    modelData.stateSchools.push({ label: state.name, value: null, isDisabled: true });
                    state.schools.map(school=>{
                        modelData.stateSchools.push({ label: school.school_name, value: school.school_id });
                    })
                })
            
        })
        .catch((e) => {
          alert(e);
        });
        
    }

    const schema = Yup.object().shape({
        firstName: Yup.string()
        .required('name is required'),
        lastName:Yup.string()
        .required('lastName is required'),
        phone:Yup.string()
        .required('phone is required'),
        userName:Yup.string()
        .required('userName is required'),
        email: Yup.string()
                .required('Email is required')
                .email('Email is invalid'),
                password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters long'),
  cPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
    hasRealEstateLicense: Yup.number(),
    realEstateLicense: Yup.string().when('hasRealEstateLicense', {
        is: 1,
        then: Yup.string().required('Real estate license is required'),
        otherwise: Yup.string().nullable()
    }),
    licenseExpiration: Yup.string().when('hasRealEstateLicense', {
        is: 1,
        then: Yup.string().required('License expiration is required'),
        otherwise: Yup.string().nullable()
    }),
    school: Yup.string().required('school is required'),
    accountType:Yup.array().min(1, "select at least one type")
    });
    const { handleSubmit,values, errors,setFieldValue,resetForm } = useForm({
        initialValues: {
        firstName: '',
        lastName: '',
        phone: '',
        zip:'',
        city:'',
        email:'',
        userName:'',
        company:'',
        password:'',
        cPassword:'',
        state:'',
        dateOfbirth:'',
        address:'',
        hasRealEstateLicense:0,
        realEstateLicense:'',
        haveDiploma:0,
        isAffiliated:0,
        school:'',
        accountType:[],
        licenseExpiration:"",
        fileInput:""

      },
      validationSchema: schema,
    });
    const uploadedFileURL = ref(null);
    const cancelUpload = () => {
      const fileInput = document.getElementById('fileInput');
      fileInput.value = '';
      values.fileInput = null;
    };
    const handleFileChange = () => {
      if (values.fileInput.type.startsWith('image/'))
        uploadedFileURL.value = URL.createObjectURL(values.fileInput);
      else cancelUpload();
    };
    const onSubmit = handleSubmit(async values => {
      activeLoader('active','add-user');
        const formData = new FormData();
       
        formData.append('first_name', values.firstName);
        formData.append('last_name', values.lastName);
        formData.append('username', values.userName);
        formData.append('birthday', UnixTimeStamp(values.dateOfbirth));
        formData.append('have_diploma', values.haveDiploma?'yes':'no');
        formData.append('school_id', values.school);
        formData.append('roles[]', values.accountType);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('company', values.company);
        formData.append('address', values.address);
        formData.append('city', values.city);
        formData.append('state', values.state);
        formData.append('postal_code', values.zip);
        formData.append('is_manual', 1);
        formData.append('commission', 0);
        formData.append('password', values.password);
        formData.append('c_password', values.cPassword);
        formData.append('affiliate', values.isAffiliated);
        
        if(values.hasRealEstateLicense){
            formData.append('hasRealEstateLicense', values.hasRealEstateLicense);
            formData.append('real_estate_license', values.realEstateLicense);
            formData.append('real_estate_license_expiration',UnixTimeStamp(values.licenseExpiration) );
        }
        if(values.fileInput){
          formData.append('inputFile', values.fileInput);
        }
        await UserService.createUser(formData).then(async (response) => {
          if(response.status==200){
            resetForm();
            closeBtn.value.click();
            activeLoader('dactive','add-user');
            notificationService.success('Rcorde SuceessFully Created!');
          }
        }).catch(e=>{
          alert(e);
        });
        console.log(values);
    });

    onMounted(()=>{
        getStates();
        getStateSchools();
        getAccountRole();
    });
    return {
      onSubmit,
      values, 
      errors,
      multiselectFocus,
      handleFileChange,
      cancelUpload,
      uploadedFileURL,
      modelData,
      closeBtn
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/components/upload.scss';
</style>