import * as API from "@/services/API";

export default {
   
    async getStates(){
        return await API.apiClient.get('/api/states');
    }, 

    async getStateSchools(){
        return await API.apiClient.get('/api/state/schools');
    },
    async getAccountRole(){
        return await API.apiClient.get('/api/roles');
    },
};