import axios from "axios";
import store from '@/store/TokenModule';

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true, // required to handle the CSRF token

});

  
  apiClient.interceptors.request.use((config) => {
    return config; 
});
/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.getters["auth/authUser"] &&
            !store.getters["auth/guest"]
        ) {
          
            store.dispatch("auth/logout");
        }if(error.response.status==401){
          localStorage.removeItem('kohanaToken');
          localStorage.removeItem('referrer');
        }
        return Promise.reject(error);
    }
);