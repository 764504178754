// store.ts
import { createStore } from 'vuex';

export default createStore({
  state: {
    token: '', // Initialize token as an empty string
  },
  mutations: {
    setToken(state, token) {
      state.token = token; // Update the token value in the store
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token); // Dispatch the mutation to set the token value
    },
  },
  getters: {
    getToken(state) {
      return state.token; // Access the token value from the store
    },
  },
});
