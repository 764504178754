<template>
  <div
      class="modal fade p-0"
      id="deleteSweetConfirmation"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="d-flex flex-wrap w-100 position-relative event-auto">
          <div class="card shadow-none">
            <div class="card-body p-0">
              <div class="row text-center py-4 px-2 mx-0">
                <div class="mb-2">
                  <i
                    class="bi bi-exclamation-triangle text-danger fs-1 lh-1"
                  ></i>
                </div>
                <h4 class="mb-2">Are you sure?</h4>
                <h6 class="mb-0 text-secondary">
                  You won't be able to revert this!
                </h6>
              </div>
              <div class="col-12 py-2 modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary py-1"
                  data-bs-dismiss="modal"
                  ref="closeBtn"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-danger"
                  @click="removeItemConfirmed()"
                >
                  Yes , Delete <i class="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import {ref} from 'vue';
  export default {
    setup(props,{emit}) {
        const closeBtn= ref();
        const removeItemConfirmed =()=>{
            emit('deleteItem',true,closeBtn);
        }
  
      return {
        removeItemConfirmed,
        closeBtn
      }
    }
  }
  </script>