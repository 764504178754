import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import CKEditor from '@ckeditor/ckeditor5-vue';
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
import { Tooltip } from 'bootstrap';
import AppNotifications from './utils/AppNotifications';
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    toastDefaults: {
        // ToastOptions object for each type of toast
        [TYPE.ERROR]: {
            timeout: 10000,
            closeButton: false,
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
            hideProgressBar: true,
        }    
    }
};
declare global {
  interface Window {
    bootstrap: any;
  }
}

// Adding bootstrap

import './assets/scss/_custom_bootstrap.scss';
// importing Bootstrap Icons
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
createApp(App).use(store).use(router).use(new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
  trigger: 'hover',
})).use(VCalendar, {}).use(CKEditor).use(Toast, options).use(AppNotifications).mount('#app');
//.use(AppNotifications)

