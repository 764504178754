<template>
   <div class="inner-content">
      <section>
         <div class="container-fluid">
            <div class="row m-0">
               <div class="col-12">
                  <div class="card">
                     <div
                        class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
                        >
                        <div class="content-card-left">
                           <h4 class="card-title text-white">{{courseName??""}} Lessons</h4>
                           <p class="card-category mb-0">
                              Here you can view lesson's data
                           </p>
                        </div>
                        <div class="content-card-right ms-auto">
                           <a
                              href="#edit"
                              data-bs-toggle="modal"
                              data-bs-target="#add"
                              @click.prevent
                              class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle"
                              >
                           <i class="bi bi-plus-square-fill text-white"></i>
                           </a>
                        </div>
                     </div>
                     <div class="card-body">
                        <div class="header-content">
                           <form
                              class="table-header mx-0 mb-1 custom-form position-sticky"
                              >
                              <div class="row m-0 align-items-center">
                                 <!-- <div
                                    class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 px-0"
                                    >
                                    <h4 class="filter mb-lg-0 mb-md-0 mb-3">
                                      <i class="bi bi-funnel me-1 position-relative"></i
                                      >Filters
                                    </h4>
                                    </div> -->
                                 <div
                                    class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-0 ms-lg-auto ms-md-auto"
                                    >
                                    <!-- <div class="position-relative w-100">
                                       <Multiselect v-model="searchData.shopId"
                                       :options="search_shops" class="custom-multiselect
                                       table-header-multi h-auto" placeholder="Search Shop"
                                       :searchable="true" :showOptions="true" mode="single"
                                       label="shopName" trackBy = "shop_name" valueProp="id"
                                       required: true />
                                       
                                       <i
                                         class="bi bi-search position-absolute search-multi"
                                       ></i>
                                       </div> -->
                                 </div>
                              </div>
                              <div class="row m-0 align-items-center">
                                 <div class="col-auto ms-auto">
                                    <div ref="customMainScrollbar" class="custom-scrollbar">
                                    <div class="scrollbar" :style="`width:${mainScrollTableWidth}px`">
                                       
                                    </div>
                                    </div>
                                 </div>
                              </div>
                           </form>
                           <div class="table-responsive mb-0">
                              <table ref='mainScrollTable' id="my-table" class="custom-table shop-table min-w-table-xl">
                                 <thead>
                                    <tr>
                                       <th class="text-capitalize">Action</th>
                                       <th class="text-capitalize">Id</th>
                                       <th class="text-capitalize">Lesson Title</th>
                                       <th class="text-capitalize">Lesson Description</th>
                                       <th class="text-capitalize">Created on</th>
                                       <th class="text-capitalize">Actions</th>
                                      
                                    </tr>
                                 </thead>
                                 <tbody>
                                  
                                    <dataLoader   v-if="!pageData.allLessons && pageData.allLessons==''"
                                     :loaderClasses="'custom-animated-loader d-inline-block position-relative'" />

                                    <template  v-for="(lesson,index) in pageData.allLessons.data"
                                       :key="lesson.id">
                                       <tr>
                                          <td>
                                             <span class="d-flex align-items-center gap-2">
                                                <span ref='pluBtn' @click="toggleTable(index),lessonId=lesson.id,getAllTopics(1,lesson.id)" class="d-inline-block pointer">
                                                <i :class="[`bi bi-${showNestedTable[index] ? 'dash' : 'plus'}-circle-fill  fs-4 text-black`]"></i>
                                                </span>
                                          
                                                <span data-bs-toggle="modal" data-bs-target="#deleteSweetConfirmation" @click="lessonId=lesson.id" class="d-inline-block pointer">
                                                   <i :class="[`bi bi-${showNestedTable[index] ? 'trash' : 'trash'}  fs-4 text-black`]"></i>
                                                </span>
                                             </span>
                                      
                                          </td>
                                          
                                          <td>{{ lesson.id }}</td>
                                          <td class="lh-base">{{ lesson.title }}</td>
                                          <td><p class="lh-base" v-html="lesson.description"></p></td>
                                          <td>
                                             {{ lesson.created_on}}
                                          </td>
                                          <td>
                                             <a
                                                href="#addTopicModal"
                                                data-bs-toggle="modal"
                                                data-bs-target="#addTopicModal"
                                                class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle border-0"
                                                @click.prevent="courseLessonId=lesson.id"
                                                >
                                                <i
                                                role="button"
                                                data-bs-toggle="tooltip"
                                                data-bs-title="Add Topic"
                                                class="bi bi-plus-square-fill text-primary lh-1 fs-5"
                                                ></i>
                                             </a>
                                       </td>
                                       </tr>
                                       <tr :class="[`new-table ${showNestedTable[index] ? '' : 'd-none'}`]">
                                          <td colspan="100%" class="p-0">
                                             <span
                                                class="table-responsive max-h-table-lg d-block mb-0">
                                                <table
                                                   class="table custom-table bg-primary-light nested-table text-white w-100 mb-0">
                                                   <thead>
                                                      <tr class='bg-primary'>
                                                         <th class="text-capitalize text-white">Id</th>
                                                         <th class="text-capitalize text-white">Title</th>
                                                         <th class="text-capitalize text-white">Exam Questions</th>
                                                         <th class="text-capitalize text-white">Exam Mandatory</th>
                                                         <th class="text-capitalize text-white">Quiz Style</th>
                                                         <th class="text-capitalize text-white text-center">Edit content</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                     
                                                      <dataLoader v-if="!pageData.allTopics && pageData.allTopics==''"
                                                      :loaderClasses="'custom-animated-loader d-inline-block position-relative loader-sm'" />
                                                   
                                                      <tr v-for="topic in pageData.allTopics.data"
                                                            :key="topic.id">
                                                         <td>{{ topic.id }}</td>
                                                         <td>{{ topic.title }}</td>
                                                         <td>{{ topic.has_questions == 1 ? 'Yes' : 'No' }}</td>
                                                         <td>
                                                            {{ topic.is_exam_mandatory == 1 ? 'Yes' : 'No' }}
                                                         </td>
                                                         <td>{{ topic.quiz_style == 1 ? 'Yes' : 'No' }}</td>
                                                         <td>
                                                            <a
                                                               href="#edit"
                                                               data-bs-toggle="modal"
                                                               data-bs-target="#edit"
                                                               @click="setEditContent(topic.id, topic.content)"
                                                               class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle border-0"
                                                               >
                                                               <i
                                                               role="button"
                                                               data-bs-toggle="tooltip"
                                                               data-bs-title="edit topic"
                                                               class="bi bi-pencil-square text-primary lh-1 fs-5"
                                                               ></i>
                                                            </a>
                                                            <!-- <i
                                                            role="button"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-title="delete course"
                                                            class="bi bi-trash-fill text-danger lh-1 fs-5"
                                                            ></i> -->
                                                         </td>
                                                      </tr>
                                                     <!-- <span class="text-primary">{{ pageData.allTopics.data.length }}</span>  -->
                                                      <noRecordeFound v-if="pageData.allTopics && pageData.allTopics.data && pageData.allTopics.data.length==0" />
                                                   </tbody>
                                                </table>
                                             </span>
                                            
                                             <div
                                                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
                                                >
                                                <p   v-if="
                                                   pageData.allTopics &&
                                                   pageData.allTopics.data &&
                                                   pageData.allTopics.data.length > 0
                                                   " class="d-lg-inline-block table-data">
                                                   Showing {{ pageData.allTopics.meta.from??'' }} to {{ pageData.allTopics.meta.to??'' }} of {{ pageData.allTopics.meta.total??'' }} entries..
                                                </p>
                                                <div
                                                   v-if="
                                                   pageData.allTopics &&
                                                   pageData.allTopics.data &&
                                                   pageData.allTopics.data.length > 0
                                                   "
                                                   class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                                                   >
                                                   <v-pagination
                                                      class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                                                      v-model="topicsPagination"
                                                      :pages="
                                                      pageData.allTopics
                                                      ? pageData.allTopics.meta.last_page
                                                      : null
                                                      "
                                                      :range-size="1"
                                                      @update:modelValue="getAllTopics"
                                                      />
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                    </template>
                                    <noRecordeFound v-if="pageData.allLessons && pageData.allLessons.data && pageData.allLessons.data.length==0" />

                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div
                        class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
                        >
                        <p   v-if="
                           pageData.allLessons &&
                           pageData.allLessons.data &&
                           pageData.allLessons.data.length > 0
                           " class="d-lg-inline-block table-data">
                           Showing {{ pageData.allLessons.meta.from??'' }} to {{ pageData.allLessons.meta.to??'' }} of {{ pageData.allLessons.meta.total??'' }} entries..
                        </p>
                        <!--<pagination :v-model="page" :records="44" :per-page="15" @paginate="myCallback"/>-->
                        <div
                           v-if="
                           pageData.allLessons &&
                           pageData.allLessons.data &&
                           pageData.allLessons.data.length > 0
                           "
                           class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                           >
                           <v-pagination
                              class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                              v-model="paginationPage"
                              :pages="
                              pageData.allLessons
                              ? pageData.allLessons.meta.last_page
                              : null
                              "
                              :range-size="1"
                              @update:modelValue="getAllLessons"
                              />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
   <AddTopicModel
   :lessonId="courseLessonId" />
   <TopicEditModal
    
      :topicId="topicId"
      :topicContent="topicContent"
      ></TopicEditModal>
   <sweatAlert @deleteItem="deleteLesson"/>
</template>
<script>
   import '@/assets/scss/pages/shop.scss';
   import lessonServices from '@/services/lessonServices';
   import { onMounted, ref, reactive, watch,inject } from 'vue';
   import VPagination from '@hennge/vue3-pagination';
   import AddTopicModel from '@/components/modals/AddTopicModel.vue';
   import TopicEditModal from '@/components/modals/TopicEditModal.vue';
   import sweatAlert from '@/components/sweatAlert.vue';
   import '@vueform/multiselect/themes/default.css';
   import { useRoute } from 'vue-router';
   import dataLoader from '@/components/dataLoader';
   import noRecordeFound from '@/components/noRecordeFound';
   
   export default {
     name: 'lessonTopics',
     components: {
       VPagination,
       TopicEditModal,
       sweatAlert,
       dataLoader,
       AddTopicModel,
       noRecordeFound
     },
     setup() {
      const notificationService = inject('$notification');
      const showNestedTable = ref([]);
      const topicsPagination = ref(1);
      const paginationPage = ref(1);
      const topicId = ref(0);
      const topicContent = ref('');
      const lessonId=ref(0);
      const pluBtn = ref([]);
      const mainScrollTable = ref();
      const mainScrollTableWidth = ref();
      const customMainScrollbar = ref();
      const courseLessonId=ref(0);
      const courseName=ref("");
       //const examQuestionFilter = ref('');
       const route = useRoute();
  
       const pageData = reactive({
         allTopics: '',
         allLessons:''
       });
   
   
       const getAllTopics = async (val = null,id=0) => {
         pageData.allTopics='';
         const serarchQuery = {
           //is_exam_question: examQuestionFilter.value,
           'lesson-id':id>0?id:lessonId.value>0?lessonId.value:''
         };
         await lessonServices
           .getAllTopics(serarchQuery, val ? val : 1)
           .then(async (response) => {
             pageData.allTopics = response.data;
           })
           .catch((e) => {
             alert(e);
           });
       };
       const getAllLessons = async (val = null) => {
         showNestedTable.value.fill(false);
         const id = Number(route.params.id);
         pageData.allLessons='';
         const serarchQuery = {
           //is_exam_question: examQuestionFilter.value,
           'course-id':isNaN(id)?'':id
         };
         await lessonServices
           .getAllLessons(serarchQuery, val ? val : 1)
           .then(async (response) => {
             pageData.allLessons = response.data;
             courseName.value=id?(response.data?.data[0] && response.data?.data[0]['course_name']?response.data?.data[0]['course_name']:''):"";
           })
           .catch((e) => {
             alert(e);
           });
       };
       onMounted(() => {
         
         getAllLessons();
           // custom scrollbar code start
      const tableScrollCalculations = ()=> mainScrollTableWidth.value =  (mainScrollTable.value.clientWidth - mainScrollTable.value.parentElement.clientWidth) + 250;
      
      tableScrollCalculations();

      const customScrollbarScroll = tableWrapper => tableWrapper.scrollLeft = customMainScrollbar.value.scrollLeft;
      
      const customTableScroll = (tableWrapper) => {
        customMainScrollbar.value.scrollLeft = tableWrapper.scrollLeft;
     
      };

      // recalculate tableScrollCalculations on pluBtn click
      pluBtn.value?.forEach(item => item?.addEventListener("click", ()=> tableScrollCalculations()));
  
      // Moving  table scroll When Custom Scrollbar Moves

      customMainScrollbar.value?.addEventListener("scroll", () => customScrollbarScroll(mainScrollTable.value.parentElement));
      

      // Moving Custom Scrollbar When table scroll Moves
      mainScrollTable.value?.parentElement?.addEventListener("scroll", () => customTableScroll(mainScrollTable.value.parentElement));
    
      // custom scrollbar code end
       });
      const toggleTable = (index)=>{
         showNestedTable.value.filter((item, i) => {
            if (i === index) return;
            showNestedTable.value[i] = false;
         });
         showNestedTable.value[index] =  !showNestedTable.value[index];
      }
       const setEditContent = (id, content) => {
         topicId.value = id;
         topicContent.value = content;
       };
   
       const deleteLesson = async (value,closeBtn)=>{
         if(value && lessonId){
            await lessonServices
            .removeLesson(lessonId.value)
            .then(async (response) => {
               if(response.status==200){
                  notificationService.success('Rcorde SuceessFully deleted!');
                  getAllLessons();
               }
            })
            .catch((e) => {
               alert(e);
            });
         }
         closeBtn.value.click();
       }
   
      
       return {

         getAllTopics,
         topicId,

         paginationPage,
         pageData,
         setEditContent,
         topicContent,
         //examQuestionFilter,
         showNestedTable,
         courseName,
         getAllLessons,
         topicsPagination,
         lessonId,
         toggleTable,
         mainScrollTable,
         mainScrollTableWidth,
         customMainScrollbar,
         pluBtn,
         deleteLesson,
         courseLessonId
       };
     },
   };
</script>