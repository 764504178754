<template>
    <textarea
    v-bind="editerVBind"
    :class="editerClasses"
    :name="editerName"
    :id="editerId"
    :value="editerValue"
    @input="$emit('update:editerValue', $event.target.value)"
    ></textarea>
    
  </template>
  
  <script>
  import { watch,onMounted } from 'vue';
  import { addBaseUrlToImages } from '@/utils/helpers';
  export default {
    props: {
      editerClasses: String,
      editerName: String,
      editerId: String,
      editerVBind: Object,
      editerValue: String,
      editerReset:Boolean,
      setEditerData:Boolean
    },
    setup(props, {emit}) {
    let editor;
    onMounted(() => {
      editor = window.CKEDITOR.replace(props.editerId);
      editor.on('change', () => {
        const updatedValue = editor.getData();
        emit('update:editerValue', updatedValue);
      });
    });


      watch(
          [() => props.setEditerData, () => props.editerReset],
          async ([setEditerData, editerReset], [oldEditerValue, oldEditerReset]) => {
            
            // Check for changes in editerValue
            if (setEditerData !== oldEditerValue) {
              
            editor.setData(addBaseUrlToImages(props.editerValue?props.editerValue:''));
         
            
            }
         
            // Check for changes in editerReset
            if (editerReset !== oldEditerReset && editerReset) {
              if(editerReset==true){
                editor.setData('<p></p>',function() { this.resetUndo(); });
              }
            }
          },
          { deep: true }
        );


        

      return {

      };
    }
  };
  </script>
  