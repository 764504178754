<template>
  <div id="app">
    <!-- SideBar Component -->
    <sidebar />
    <div class="main-content">
      <!-- navbar Component -->
      <navbar />
      <!-- Inner content -->
      <router-view />
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar";
import Navbar from "@/components/Nav";
import { useRouter } from "vue-router";
import { computed, onMounted, watch } from "vue";
import {useStore} from "vuex";
import storeToken from '@/store/TokenModule';

export default {
  name: "Wrapper",
  components: {
    sidebar: Sidebar,
    navbar: Navbar,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    
    const user = computed(() => store.state.User.user);
    watch(user,()=>{
      if(user.value.is_active!=1){
        router.push('/');
      } 
    })
    
    onMounted(async () => {
      if(user.value.is_active!=1){
        try{
       
          const data = await store.dispatch("Auth/getAuthUser");
          
          console.log(data);
          if(data.data.is_active!==1)
            router.push('/');
        }
        catch{
          router.push('/');
        }
      } 
    });
    return {};
  },
};
</script>