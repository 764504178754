import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import Login from '@/pages/Login.vue';
import Wrapper from '@/pages/Wrapper.vue';
import AuthService from '@/services/AuthService';
import Lessons from '@/pages/lessonTopics.vue';
import Courses from '@/pages/Courses.vue';
import Users from '@/pages/Users.vue';
import Packages from '@/pages/Packages.vue';
import UserTransactions from '@/pages/UserTransactions.vue';
import store from '@/store/TokenModule';
import {useNotificationService} from '../utils/AppNotifications';
const notificationService = useNotificationService();

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'Login', component: Login },
  {
    path: '/wrapper',
    component: Wrapper,
    children: [
      { path: '/lessons/:id', name: 'lessonTopic', component: Lessons },
      { path: '/courses', component: Courses },
      { path: '/users', component: Users },
      { path: '/packages', component: Packages },
      { path: '/transactions/:id', name: 'usersTransactions', component: UserTransactions },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userStoredToken= await store.getters.getToken ?store.getters.getToken : localStorage.getItem('kohana_token')?localStorage.getItem('kohana_token'):'';
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const token = parameters.get('kohana_token') || '';
  if (token !== '' && !userStoredToken) {
    try {
      await AuthService.login({
        email: 'kohana@kohana.com',
        password: token,
        kohana_token: token,
      }).then(async res=>{
          localStorage.setItem('kohana_token',token);
          await store.dispatch('setToken', token);
        next({
          path: to.path,
        });
      }).catch(e=>{
       
        window.location.href=process.env.BASE_URL;
        notificationService.error('Invalid Creadentials!');
        console.log(e,'---------------');
       
      });
     
    } catch (error) {
     
      window.location.href=process.env.BASE_URL;
       notificationService.error('Invalid Creadentials!');
      console.log(error,'---------------');
    }
    if (window.location.href.substring(10) !== document.referrer.substring(10)) {
      localStorage.setItem('referrer', document.referrer);
    }
  }
  next();
});

export default router;
